import 'dayjs/locale/fr';
import './src/styles/utilities.css';
import './src/styles/admin.css';

import { FormidableProvider } from 'dataformjs';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Translation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { setUserRole } from './src/actions';
import apolloClient from './src/apolloClient';
import Button from './src/components/base/Button';
import {
  getControlStyle,
  InputGroupSC,
  InputSC,
  SelectSC,
  TextareaSC,
} from './src/components/base/Field/style';
import LabelSC from './src/components/base/Input/Label/index';
import {
  ColumnSC,
  ColumnsSC,
  FieldMessageSC,
  FieldTemplateSC,
  FieldWrapperSC,
  GroupDescriptionSC,
  GroupSC,
} from './src/components/base/styles';
import { TabsBarItemSC, TabsBarSC, TabsSC } from './src/components/base/Tabs';
import extendData from './src/components/form';
import IconAdd from './src/components/icons/Add';
import IconBack from './src/components/icons/Back';
import IconDelete from './src/components/icons/Delete';
import IconNext from './src/components/icons/Next';
import IconStep from './src/components/icons/Step';
import IconSuccess from './src/components/icons/Success';
import { auth } from './src/firebase';
import i18n from './src/i18n';
import User from './src/models/User';
import authReducer from './src/reducers/auth';
import theme from './src/styles/theme';

dayjs.locale('fr');

const initializeStore = store => {
  if (typeof document !== `undefined` && typeof window !== `undefined`) {
    auth.onAuthStateChanged(async user => {
      if (user) {
        const role = await User.getToken(user);
        console.info('user role', role);
        store.dispatch(setUserRole(role));
      } else {
        User.logout();
      }
    });
  }
};

const WrapRootElement = ({ element }) => {
  // process.env.BROWSER && window.State ? window.State.redux : {};

  if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
    window.navigator.serviceWorker
      .getRegistrations()
      .then(registrations => registrations.forEach(r => r.unregister()))
      .catch(error => {
        console.error(error);
      });
  }

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <Translation i18n={i18n}>
          {t => (
            <FormidableProvider
              extendData={extendData}
              extraReducers={{ auth: authReducer }}
              getControlStyle={getControlStyle}
              initializeStore={initializeStore}
              sc={{
                button: Button,
                column: ColumnSC,
                columns: ColumnsSC,
                fieldMessage: FieldMessageSC,
                fieldTemplate: FieldTemplateSC,
                fieldWrapper: FieldWrapperSC,
                group: GroupSC,
                groupDescription: GroupDescriptionSC,
                iconAdd: IconAdd,
                iconBack: IconBack,
                iconNext: IconNext,
                iconRemove: IconDelete,
                iconStep: IconStep,
                iconSuccess: IconSuccess,
                input: InputSC,
                inputGroup: InputGroupSC,
                label: LabelSC,
                select: SelectSC,
                tabs: TabsSC,
                tabsBar: TabsBarSC,
                tabsBarItem: TabsBarItemSC,
                textarea: TextareaSC,
              }}
              t={t}
              theme={theme}
            >
              {element}
            </FormidableProvider>
          )}
        </Translation>
      </ThemeProvider>
    </ApolloProvider>
  );
};

WrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = WrapRootElement;

// export const onInitialClientRender = () => {
//   if (process.env.BUILD_STAGE !== 'build-javascript') {
//     return;
//   }
//
//   // Remove the server-side injected state.
//   const preloadedStateEl = document.getElementById(ELEMENT_ID);
//   if (preloadedStateEl) {
//     preloadedStateEl.parentNode.removeChild(preloadedStateEl);
//   }
// };
