import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  appId: process.env.GATSBY_FIREBASE_API_ID,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app, 'europe-west1');

if (
  'development' === process.env.NODE_ENV &&
  'true' === String(process.env.GATSBY_USE_EMULATOR)
) {
  console.info('connect emulator');
  connectFunctionsEmulator(
    functions,
    String(process.env.GATSBY_FUNCTIONS_EMULATOR_HOST),
    Number(process.env.GATSBY_FUNCTIONS_EMULATOR_PORT),
  );
  connectFirestoreEmulator(
    firestore,
    String(process.env.GATSBY_FIRESTORE_EMULATOR_HOST),
    Number(process.env.GATSBY_FIRESTORE_EMULATOR_PORT),
  );
  connectAuthEmulator(
    auth,
    String(process.env.GATSBY_FIREBASE_AUTH_EMULATOR_HOST),
  );
}

export default app;

export { auth, firestore, functions };
