import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import apolloLogger from 'apollo-link-logger';
// import { RetryLink } from 'apollo-link-retry';
// import { WebSocketLink } from 'apollo-link-ws';
// import { getMainDefinition } from 'apollo-utilities';
import fetch from 'isomorphic-fetch';

// import { SubscriptionClient } from 'subscriptions-transport-ws';

// import noriia from '../functions/src/utils/noriia';

const cache = new InMemoryCache({ addTypename: true });

// const GRAPHQL_ENDPOINT = 'ws://localhost:4040/graphql';
//
// const client = process.browser
//   ? new SubscriptionClient(GRAPHQL_ENDPOINT, {
//       reconnect: true,
//     })
//   : null;
//
// const wsLink = process.browser ? new WebSocketLink(client) : null;

export const noriiaLink = new HttpLink({
  fetch,
  uri: process.env.GATSBY_NORIIA_GRAPHQL_URI,
});

const middlewareLink = new ApolloLink((operation, forward) => {
  const token =
    'undefined' !== typeof localStorage
      ? localStorage.getItem('token')
      : undefined;

  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }

  return forward(operation);
});

export default new ApolloClient({
  cache,
  connectToDevTools: 'development' === process.env.NODE_ENV,
  link: from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    ...('development' === process.env.NODE_ENV ? [apolloLogger] : []),
    middlewareLink.concat(noriiaLink),
  ]),
  queryDeduplication: true,
  ssrMode: !process.browser,
});
