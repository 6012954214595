import { rem } from 'polished';
import styled from 'styled-components';

import { colors, font, mediaQueryMin, screens } from '../../../../styles/theme';

const TimelineStepBullet = styled.div`
  position: relative;
  flex-shrink: 0;
  margin-top: ${rem(1)};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${rem(24)};
  line-height: ${rem(24)};
  width: ${rem(24)};
  background: ${colors.primary.base};
  color: ${colors.light['300']};
  font-weight: ${font.weight.bold};
  border-radius: 50%;
  ${mediaQueryMin(screens.tablet)} {
    height: ${rem(24)};
    line-height: ${rem(24)};
    width: ${rem(24)};
  }
  @media print {
    display: none;
  }
`;

export default TimelineStepBullet;
