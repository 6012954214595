import styled from 'styled-components';

import { colors, spacing } from '../../../../../styles/theme';

const ModalContentHeader = styled.div`
  z-index: 1;
  position: sticky;
  top: 0;
  padding: ${spacing.l} ${spacing.m};
  background: ${colors.light['300']};
  text-align: center;
  border-bottom: 1px solid ${colors.neutral.lighter};
`;

export default ModalContentHeader;
