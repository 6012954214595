import { rem } from 'polished';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { colors, font, StatusProp } from '../../../styles/theme';

const getFieldMessageStatusStyles = ({
  status,
}: StatusProp): FlattenSimpleInterpolation => {
  switch (status) {
    case 'error':
      return css`
        color: ${colors.danger.base};
      `;
    case 'warning':
      return css`
        color: ${colors.warning.base};
      `;
    default:
      return css`
        color: ${colors.neutral.base};
      `;
  }
};

const FieldMessage = styled.div<StatusProp>`
  transform: translateY(${rem(4)});
  font-size: ${font.size.xs[0]};
  ${(props): FlattenSimpleInterpolation => getFieldMessageStatusStyles(props)};
`;

export default FieldMessage;
