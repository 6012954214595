import React, { FC, ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';

import Button from '../../base/Button';
import IconDelete from '../../icons/Delete';
import { ListChildrenProps } from './List';

const GroupSC = styled.fieldset``;
const GroupTitleSC = styled.legend``;
const GroupBodySC = styled.div``;

interface GroupProps extends ListChildrenProps {
  as?: any;
  className?: string;
  customInfos?: ReactElement;
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  id: string;
  title?: string;
}
const Group: FC<GroupProps> = ({
  as,
  children,
  className,
  customInfos,
  deleteOnClick,
  id,
  selectedItems,
  selectedItemOnChange,
  title,
}) => {
  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    const ok = window.confirm('Etes-vous sûr de vouloir supprimer ce groupe ?');

    if (deleteOnClick && ok) {
      deleteOnClick(event);
    }
  };

  return (
    <GroupSC as={as} className={className || 'relative'}>
      {title && (
        <GroupTitleSC className="flex font-bold ml-6">
          <label htmlFor={id}>{title}</label>
        </GroupTitleSC>
      )}

      <GroupBodySC className="flex-1 flex flex-row space-x-3">
        {selectedItemOnChange && (
          <div>
            <input
              checked={selectedItems ? selectedItems[id] : false}
              id={id}
              onChange={selectedItemOnChange}
              type="checkbox"
            />
          </div>
        )}
        <div className="flex-1">{children}</div>
        {(customInfos || (id && deleteOnClick)) && (
          <div>
            {customInfos}

            {deleteOnClick && (
              <Button
                className="text-light-900"
                datas={{ 'data-id': id }}
                iconLeft={IconDelete}
                onClick={handleDeleteOnClick}
                size="small"
                status="link"
              />
            )}
          </div>
        )}
      </GroupBodySC>
    </GroupSC>
  );
};
export default Group;
