import React, { FC } from 'react';
import styled from 'styled-components';

import {
  colors,
  font,
  lineHeight,
  mediaQueryMax,
  screens,
} from '../../../styles/theme';

interface TitleProps {
  as?: React.ElementType;
  className?: string;
  color?: string;
  id?: string;
  size?: string;
  style?: React.CSSProperties;
}

const TitleEl = styled.div<TitleProps>`
  line-height: ${lineHeight.s};
  font-weight: ${font.weight.bold};
  font-size: ${({ size }: TitleProps) => size || font.size.l[0]};
  ${mediaQueryMax(screens.tablet)} {
    font-size: ${({ size }: TitleProps) => size || font.size.l[0]};
  }
  color: ${props => props.color};
  &::first-letter {
    text-transform: uppercase;
  }
  @media print {
    color: ${colors.print.base};
  }
`;

/**
 * Title
 *
 * @param {object} props
 * @param {string} props.as
 * @param {React.ReactNode} props.children
 * @param {string} props.className
 * @param {string} props.color
 * @param {number} props.size
 * @param {object} props.style
 * @param {string} props.id
 *
 * @returns {React.ReactElement}
 */
const Title: FC<TitleProps> = ({
  as = 'div',
  children,
  className,
  color = colors.neutral.darker,
  id,
  size = font.size.l[0],
  style,
}) => (
  <TitleEl
    as={as}
    className={className}
    color={color}
    id={id}
    size={size}
    style={style}
  >
    {children}
  </TitleEl>
);

export default Title;
