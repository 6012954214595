import React, { FC } from 'react';

import IconProps from '../props';

/* eslint-disable max-len */

const IconStep: FC<IconProps> = ({ className, color, size = 16 }) => (
  <svg className={className} height={size} viewBox="0 0 16 16" width={size}>
    <path
      d="M9.33031 8.00115L3.90104 13.4276C3.31294 14.0157 3.31294 14.9717 3.90104 15.5584C4.48914 16.1465 5.44374 16.1479 6.03184 15.5584L12.5876 9.00547C13.1416 8.45004 13.1416 7.55084 12.5876 6.99684L6.03326 0.441076C5.44516 -0.147025 4.49056 -0.147025 3.90246 0.441076C3.31436 1.02918 3.31436 1.98378 3.90246 2.57188L9.32747 8.00257L9.33031 8.00115Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconStep;
