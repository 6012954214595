import { DataProps } from 'dataformjs';
import React, { ReactElement } from 'react';

import StepLoading from './Loading';
import StepUnloading from './Unloading';

const Data = (
  props: DataProps & {
    formName: string;
    params: { [key: string]: any };
  },
): ReactElement<any, any> | null => {
  const { componentType, formName, formValues, name } = props;

  switch (componentType) {
    case 'step/loading': {
      if (!name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <StepLoading
          {...props}
          formName={formName}
          formValues={formValues}
          name={name}
        />
      );
    }

    case 'step/unloading': {
      if (!name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <StepUnloading
          {...props}
          formName={formName}
          formValues={formValues}
          name={name}
        />
      );
    }

    default:
  }

  return null;
};

export default Data;
