import classnames from 'classnames';
import React, { FC, ReactElement, SyntheticEvent } from 'react';

import Button from '../../base/Button';
import IconDelete from '../../icons/Delete';
import IconEdit from '../../icons/Edit';
import IconSearchLocation from '../../icons/SearchLocation';
import { ListChildrenProps } from '../Body/List';
import ModalOpen from '../Modal/Open';

interface ItemProps extends ListChildrenProps {
  className?: string;
  customInfos?: ReactElement;
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  editPathname?: string;
  header?: {
    className?: string;
  };
  icon?: ReactElement;
  id: string;
  modal?: ReactElement;
  title: string | ReactElement;
  showPathname?: string;
  subTitle?: string | ReactElement;
}
const Index: FC<ItemProps> = ({
  children,
  className,
  customInfos,
  deleteOnClick,
  editPathname,
  header,
  icon,
  id,
  modal,
  selectedItems,
  selectedItemOnChange,
  showPathname,
  subTitle,
  title,
}) => {
  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    const ok = window.confirm(
      'Etes-vous sûr de vouloir supprimer cet élément ?',
    );

    if (deleteOnClick && ok) {
      deleteOnClick(event);
    }
  };

  return (
    <div
      className={classnames(
        'relative bg-light-500 p-6 rounded flex flex-col space-y-6',
        className,
      )}
    >
      <div
        className={classnames('flex space-x-3 items-center', header?.className)}
      >
        {icon && <span>{icon}</span>}
        <div className="flex flex-col">
          <label className=" font-bold text-m" htmlFor={id}>
            {title}
          </label>
          {subTitle && <span className="text-s">{subTitle}</span>}
        </div>
      </div>

      {(customInfos ||
        (id && (deleteOnClick || showPathname || editPathname))) && (
        <div className="absolute flex space-x-2 right-6 top-0">
          {customInfos}

          {modal && <ModalOpen id={id} modal={modal} title={title} />}
          {!modal && showPathname && (
            <Button
              className="text-light-900"
              iconLeft={IconSearchLocation}
              size="small"
              status="tertiary"
              to={`${showPathname}${id}/`}
            />
          )}
          {!modal && editPathname && (
            <Button
              className="text-light-900"
              datas={{ 'data-id': id }}
              iconLeft={IconEdit}
              size="small"
              status="tertiary"
              to={`${editPathname}${id}/`}
            />
          )}

          {deleteOnClick && (
            <Button
              className="text-light-900"
              datas={{ 'data-id': id }}
              iconLeft={IconDelete}
              onClick={handleDeleteOnClick}
              size="small"
              status="tertiary"
            />
          )}
        </div>
      )}
      {selectedItemOnChange && (
        <div className="absolute flex space-x-3 left-0 top-1">
          <input
            checked={selectedItems ? selectedItems[id] : false}
            id={id}
            onChange={selectedItemOnChange}
            type="checkbox"
          />
        </div>
      )}
      {children}
    </div>
  );
};
export default Index;
