import React, { FC, SyntheticEvent, useState } from 'react';
import styled from 'styled-components';

const ListSC = styled.div``;

interface ListProps {
  as?: any;
  className?: string;
  selectedItemsOnChange?: (values: { [key: string]: boolean }) => void;
}

export interface ListChildrenProps {
  selectedItems?: {
    [key: string]: boolean;
  };
  selectedItemOnChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
}

const List: FC<ListProps> = ({
  as,
  children,
  className,
  selectedItemsOnChange,
}) => {
  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: boolean;
  }>({});

  const handleSelectedItemOnChange = (
    event: SyntheticEvent<HTMLInputElement>,
  ) => {
    const { checked, id } = event.currentTarget;

    setSelectedItems(oldSelectedItems => {
      const newSelectedItems = {
        ...oldSelectedItems,
        [id]: checked,
      };

      if (selectedItemsOnChange) {
        selectedItemsOnChange(newSelectedItems);
      }

      return newSelectedItems;
    });
  };

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        selectedItems,
        selectedItemOnChange: selectedItemsOnChange
          ? handleSelectedItemOnChange
          : undefined,
      });
    }

    return child;
  });

  return (
    <ListSC as={as} className={className || 'flex flex-col space-y-6'}>
      {childrenWithProps}
    </ListSC>
  );
};

export default List;
