import React, { FC } from 'react';

import IconProps from '../props';

/* eslint-disable max-len */

// <path
//   d="M8 0C3.582 0 0 3.582 0 8C0 12.419 3.582 16 8 16C12.418 16 16 12.419 16 8C16 3.582 12.418 0 8 0ZM12.566 6.167L6.966 11.767C6.81 11.923 6.605 12.001 6.4 12.001C6.195 12.001 5.99 11.923 5.834 11.767L3.434 9.367C3.122 9.054 3.122 8.549 3.434 8.236C3.747 7.923 4.253 7.923 4.566 8.236L6.4 10.069L11.434 5.035C11.747 4.722 12.253 4.722 12.566 5.035C12.878 5.348 12.878 5.853 12.566 6.165V6.167Z"
//   fill={color || 'currentColor'}
// />

const IconSuccess: FC<IconProps> = ({ color, size = 16 }) => (
  <svg height={size} viewBox="0 0 16 16" width={size}>
    <g transform="matrix(1,0,0,1,-5,-5.65102)">
      <path
        d="M20.42,10.021L11.32,19.121C11.066,19.375 10.733,19.502 10.4,19.502C10.067,19.502 9.734,19.375 9.48,19.121L5.58,15.221C5.073,14.713 5.073,13.892 5.58,13.384C6.089,12.875 6.911,12.875 7.42,13.384L10.4,16.362L18.58,8.182C19.089,7.673 19.911,7.673 20.42,8.182C20.927,8.69 20.927,9.511 20.42,10.018L20.42,10.021Z"
        fill={color || 'currentColor'}
      />
    </g>
  </svg>
);

export default IconSuccess;
