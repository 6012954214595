import { Link as GatsbyLink } from 'gatsby';
import React, { FC } from 'react';

/**
 * Link
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.className
 * @param {string} props.id
 * @param {boolean} props.partiallyActive
 * @param {string} props.title
 * @param {string} props.to
 *
 * @returns {React.ReactElement}
 */
const Link: FC<{
  className?: string;
  id?: string;
  partiallyActive?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top' | string;
  title?: string;
  to: string;
}> = ({ children, className, id, partiallyActive, target, title, to }) => {
  const internal = /^\/(?!\/)/.test(to);
  if (internal && target !== '_blank') {
    return (
      <GatsbyLink
        activeClassName="is-active"
        className={className}
        id={id}
        partiallyActive={partiallyActive}
        target={target}
        to={to}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a
      className={className}
      href={to}
      rel="noopener noreferrer"
      target="_blank"
      title={title}
    >
      {children}
    </a>
  );
};

Link.defaultProps = {
  className: undefined,
  partiallyActive: false,
  title: undefined,
};

export default Link;
