import { DataFieldProps } from 'dataformjs';
import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import { GroupBase, Options, OptionsOrGroups } from 'react-select';

import { placesSearch as searchMutation } from '../../../objects/places/mutations';
import VehicleType from '../../../types/Vehicle';
import DataSelect from '../../datas/Select';

interface DataVehiclesSelectProps extends DataFieldProps {
  formName: string;
  name: string;
  params: {
    spaceId: string;
  };
}

const DataVehiclesSelect: FC<
  Omit<DataVehiclesSelectProps, 'componentType'>
> = props => {
  const { formName, params } = props;
  const [searchMutationFunc] = useMutation(searchMutation);

  const handleGetOptionLabel = (option: any): string => option?.name ?? '';
  const handleGetOptionValue = (option: any): string => option.id;

  const handleIsOptionSelected = (
    option: any,
    optionsSelected: Options<VehicleType>,
  ): boolean => optionsSelected.some((o: VehicleType) => o.id === option.id);

  const handleLoadOptions = async (
    inputValue: string,
  ): Promise<OptionsOrGroups<VehicleType, GroupBase<VehicleType>>> => {
    let options: VehicleType[] = [];
    const result = await searchMutationFunc({
      variables: {
        hitsPerPage: 50,
        q: inputValue,
        spaceId: params.spaceId,
      },
    });

    if (
      result &&
      result.data &&
      result.data.vehiclesSearch &&
      result.data.vehiclesSearch.hits
    ) {
      options = result.data.vehiclesSearch.hits;
    }

    return options;
  };

  return (
    <DataSelect
      {...props}
      formName={formName}
      getOptionLabel={handleGetOptionLabel}
      getOptionValue={handleGetOptionValue}
      isOptionSelected={handleIsOptionSelected}
      loadOptions={handleLoadOptions}
    />
  );
};

export default DataVehiclesSelect;
