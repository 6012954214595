// import dayjs from 'dayjs';
import objectHash from 'object-hash';
import React, { FC } from 'react';

import RouteType from '../../../../types/Route';
import Step from './Step';

const Steps: FC<{
  formName: string;
  route: RouteType;
}> = ({ formName, route }) => {
  const { steps } = route;

  return (
    <>
      {steps &&
        steps.length > 0 &&
        steps.map((step, index) => (
          <Step
            key={objectHash({ index, step })}
            formName={formName}
            step={step}
            stepIndex={index + 1}
          />
        ))}
    </>
  );
};

export default Steps;
