import React, { FC } from 'react';

import IconProps from '../props';

/* eslint-disable max-len */

const IconWatch: FC<IconProps> = ({ color, size = 16 }) => (
  <svg height={size} viewBox="0 0 16 16" width={size}>
    <path
      d="M8 0C12.4183 0 16 3.58171 16 8C16 12.4183 12.4183 16 8 16C3.58171 16 0 12.4183 0 8C0 3.58171 3.58171 0 8 0ZM8 2C4.68571 2 2 4.68571 2 8C2 11.3143 4.68571 14 8 14C11.3143 14 14 11.3143 14 8C14 4.68571 11.3143 2 8 2ZM9 4V7.57714L12.3303 9.50057L11.3303 11.232L7 8.73257V4H9Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconWatch;
