import { change, useDispatch, WrappedFieldProps } from 'dataformjs';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionMeta, OnChangeValue } from 'react-select';
import AsyncSelect from 'react-select/async';

import FieldMessageSC from '../../base/Field/Message';
import { FieldTemplateSC } from '../../base/styles';
import { DataFieldAsyncSelectProps } from './index';

const DataSelectRender: ComponentType<
  WrappedFieldProps & DataFieldAsyncSelectProps<any, boolean>
> = ({
  customAction,
  customActionProps,
  fieldProps,
  // formatOptionLabel,
  formName,
  // getOptionLabel,
  // getOptionValue,
  input: { name, value },
  // isOptionSelected,
  // loadingMessage,
  // loadOptions,
  message,
  meta: { error, touched, warning },
  // noOptionsMessage,
  onChangeValue,
  // placeholder,
  templateProps,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnChange = (
    newValue: OnChangeValue<any, boolean>,
    actionMeta: ActionMeta<any>,
  ) => {
    if (onChangeValue) {
      onChangeValue(newValue, actionMeta);
    } else {
      dispatch(change(formName, name, newValue));
    }
  };

  return (
    <FieldTemplateSC {...templateProps}>
      <div {...fieldProps}>
        <AsyncSelect
          {...props}
          cacheOptions
          defaultOptions
          defaultValue={value}
          onChange={handleOnChange}
        />
      </div>

      {customAction && <div {...customActionProps}>{customAction}</div>}

      {touched &&
        ((error && (
          <FieldMessageSC status="error">{t ? t(error) : error}</FieldMessageSC>
        )) ||
          (warning && (
            <FieldMessageSC status="warning">
              {t ? t(warning) : warning}
            </FieldMessageSC>
          )))}
      {message && <FieldMessageSC>{t ? t(message) : message}</FieldMessageSC>}
    </FieldTemplateSC>
  );
};

export default DataSelectRender;
