/* eslint-disable camelcase */
import { change, DataFieldProps, useDispatch } from 'dataformjs';
import React, { FC, ReactNode, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  Options,
  OptionsOrGroups,
} from 'react-select';
import { v4 as uuidv4 } from 'uuid';

import { addressesAutocomplete } from '../../../objects/addresses/mutations';
import DataSelect from '../../datas/Select';

// export interface ItemProps {
//   description: string;
//   structured_formatting: {
//     main_text: string;
//     secondary_text: string;
//   };
//   label: string;
//   name: string;
//   place_id: string;
//   value: string;
// }

interface DataAddressProps extends Omit<DataFieldProps, 'componentType'> {
  componentParams?: { [key: string]: any };
  formName: string;
  name: string;
}

const DataAddressSearch: FC<DataAddressProps> = ({
  componentParams,
  formName,
  id,
  name,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sessionToken] = useState(uuidv4());
  const [addressesAutocompleteMutationFunc] = useMutation(
    addressesAutocomplete,
  );

  const handleIsOptionSelected = (
    option: any,
    selectValue: Options<any>,
  ): boolean =>
    selectValue.some((o: any) => o.googlePlaceId === option.googlePlaceId);

  const handleOnChangeValue = (
    newValue: OnChangeValue<any, false>,
    actionMeta: ActionMeta<any>,
  ): void => {
    dispatch(change(formName, 'name', newValue.name));
    dispatch(change(formName, 'formattedAddress', newValue.formattedAddress));
  };

  // const handleGetOptionLabel = (option: any): string => option?.name ?? '';
  // const handleGetOptionValue = (option: any): string => option.id;

  const handleLoadOptions = async (
    inputValue: string,
  ): Promise<OptionsOrGroups<any, GroupBase<any>>> => {
    const result = await addressesAutocompleteMutationFunc({
      variables: {
        address: inputValue,
        session: sessionToken,
      },
    });

    if (
      result &&
      result.data &&
      result.data.addressesAutocomplete &&
      result.data.addressesAutocomplete.predictions
    ) {
      return result.data.addressesAutocomplete.predictions.map(
        ({
          description,
          place_id: googlePlaceId,
          structured_formatting: {
            main_text: mainText,
            secondary_text: formattedAddress,
          },
        }: any) => ({
          description,
          formattedAddress,
          googlePlaceId,
          name: mainText,
        }),
      );
    }

    return [];
  };

  const handleLoadingMessage = (): ReactNode =>
    t('form.address.search.loading-message');
  const handleNoOptionsMessage = (): ReactNode =>
    t('form.address.search.no-options-message');

  const handleFormatOptionLabel = (
    option: any,
    {
      context,
    }: {
      context: 'menu' | 'value';
      inputValue?: string;
      selectValue?: any | any[];
    },
  ): ReactNode => {
    if ('value' === context) {
      return (
        (componentParams &&
          componentParams.label &&
          option[componentParams.label]) ||
        option.formattedAddress
      );
    }

    return (
      <>
        <strong>{option.name}</strong>
        <span className="block">{option.formattedAddress}</span>
      </>
    );
  };

  return (
    <DataSelect
      {...props}
      formName={formName}
      formatOptionLabel={handleFormatOptionLabel}
      // getOptionLabel={handleGetOptionLabel}
      // getOptionValue={handleGetOptionValue}
      isOptionSelected={handleIsOptionSelected}
      loadOptions={handleLoadOptions}
      loadingMessage={handleLoadingMessage}
      name={name}
      noOptionsMessage={handleNoOptionsMessage}
      onChangeValue={handleOnChangeValue}
      placeholder={placeholder}
    />
  );
};

export default DataAddressSearch;
