import { DataProps, useSelector } from 'dataformjs';
import objectHash from 'object-hash';
import React, { FC } from 'react';

import PlaceType from '../../../../types/Place';
import List from '../../../new/Body/List';
import Item from '../../../new/Item';

interface RoutePlacesProps extends DataProps {
  formName: string;
}

const RoutePlaces: FC<RoutePlacesProps> = ({ formName }) => {
  const { steps } = useSelector((state: any) => state.form[formName].values);

  const places: PlaceType[] = steps.map((step: { place: any }) => step.place);

  return (
    <List>
      {places?.map(place => (
        <Item
          key={objectHash({
            placeId: place.id,
          })}
          editPathname="/admin/places/update/"
          id={place.id}
          title={place.name}
        >
          <span>{place.formattedAddress}</span>
        </Item>
      ))}
    </List>
  );
};

export default RoutePlaces;
