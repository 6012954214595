import { rem, rgba } from 'polished';
import styled, {
  css,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';
import tw from 'twin.macro';

import down from '../../../images/icons/down.svg';
import {
  colors,
  control,
  DefaultThemeProps,
  StatusProp,
  transition,
} from '../../../styles/theme';

const getStatusStyle = ({ status }: StatusProp): FlattenSimpleInterpolation => {
  switch (status) {
    case 'error':
      return css`
        border-color: ${colors.danger.base};
      `;
    case 'warning':
      return css`
        border-color: ${colors.warning.base};
      `;
    default:
      return css`
        border-color: ${control.border.color.base};
      `;
  }
};

export const inputDisabledStyles = css`
  opacity: 0.5;
  pointer-events: none;
`;

export const getControlStyle = (
  props: ThemeProps<DefaultThemeProps> & StatusProp,
): FlattenSimpleInterpolation => css`
  ${getStatusStyle(props)};
  display: flex;
  border-style: ${control.border.type};
  border-width: ${control.border.size.base}px;
  border-radius: ${control.radius.base};
  background-color: ${colors.primary.lightest};
  border-radius: ${control.radius.base};
  line-height: ${control.lineHeight.base};
  ${tw`px-4`};
  transition: border-color ${transition.timing.base} ${transition.easing.base},
    box-shadow ${transition.timing.base} ${transition.easing.base},
    background-color ${transition.timing.base} ${transition.easing.base};

  &:not([type='checkbox']):not([type='radio']):focus,
  &:not([type='checkbox']):not([type='radio']):focus-within {
    outline: none;
    box-shadow: 0 0 ${rem(1)} ${rem(3)}
      ${rgba(control.border.color.hover, 0.125)};
  }
  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${control.border.color.hover};
    background-color: ${rgba(colors.primary.lightest, 0.4)};
  }
  &:disabled {
    ${inputDisabledStyles};
    border-color: ${control.border.color.base};
  }

  ::placeholder {
    color: hsl(0, 0%, 50%);
  }
`;

export const ControlSC = styled.div`
  ${getControlStyle};
  display: block;
  width: 100%;
`;

export const InputSC = styled(ControlSC).attrs({ as: 'input' })`
  &[type='checkbox'] {
    width: auto;
    ${tw`mr-4`}
  }
`;

export const InputGroupSC = styled.div`
  ${tw`mb-4`}
`;

export const SelectSC = styled(ControlSC).attrs({ as: 'select' })`
  padding-right: ${control.padding.x.base};
  background-image: url(${down});
  background-repeat: no-repeat, repeat;
  background-position: right ${control.padding.x.base} top 50%, 0 0;
  background-size: ${rem(12)} auto, 100%;
  option {
    font-weight: normal;
  }
`;

export const TextareaSC = styled(ControlSC).attrs({ as: 'textarea' })`
  line-height: 1.5rem;
  ${tw`py-3`}
`;

export default styled.div``;
