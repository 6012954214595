import compact from 'lodash/compact';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ContainerType from '../../../types/Container';
import PlaceType from '../../../types/Place';
import ItemSub from '../Item/Sub';

interface ContainerItemProps {
  item: ContainerType;
  place?: PlaceType;
  placeType?: 'loading' | 'unloading';
}

const ContainerItem: FC<ContainerItemProps> = ({ item, place, placeType }) => {
  const { t } = useTranslation();

  const {
    name,
    weight,
    depth,
    height,
    width,
    isBio,
    hasPallet,
    isRefrigerated,
    temperature,
    isPacked,
    quantity,
  } = item;

  const volume = Math.round((depth * height * width) / 1000) / 1000;

  const subTitles = [];
  if (place && placeType) {
    subTitles.push(
      t(`container.place`, {
        address: place.formattedAddress,
        context: placeType,
      }),
    );
  }
  subTitles.push(
    t('container.hasPallet.description', {
      context: hasPallet ? 'true' : 'false',
    }),
  );
  subTitles.push(
    t('container.isBio.description', { context: isBio ? 'true' : 'false' }),
  );
  subTitles.push(
    t('container.is-packed.description', {
      context: isPacked ? 'true' : 'false',
    }),
  );
  subTitles.push(
    t('container.is-refrigerated.description', {
      context: isRefrigerated ? 'true' : 'false',
      maxTemperature: temperature,
    }),
  );

  return (
    <ItemSub
      className="flex space-x-2 !mr-0"
      subTitle={compact(subTitles).join(', ')}
      subTitleClassName="!text-light-800 lowercase"
      title={
        <div className="flex space-x-2">
          <span>{name}</span>
          <span className="font-light text-light-900">
            {quantity && `${quantity} x`}
            {weight &&
              ` ${weight}
  kg / `}
            {volume}m³
          </span>
        </div>
      }
    />
  );
};

export default ContainerItem;
