import { DataProps, useSelector } from 'dataformjs';
import objectHash from 'object-hash';
import React, { FC } from 'react';

import ActivityType from '../../../../types/Activity';
import Group from '../../../new/Body/Group';
import List from '../../../new/Body/List';
import ContainerItem from '../../../new/Container/Item';

interface StepLoadingProps extends DataProps {
  formName: string;
}

const StepLoading: FC<StepLoadingProps> = ({ formName }) => {
  const step = useSelector((state: any) => state.form[formName].values);
  const { place, activities } = step;
  const unloadings = activities.filter(
    (activity: any) => activity.place.id === place.id,
  );

  if (unloadings && unloadings.length > 0) {
    return (
      <List>
        {unloadings.map((activity: ActivityType) => {
          const { user, deliveries } = activity;

          return (
            <div
              key={activity.id}
              className="bg-dark-50 px-2 py-3 md:p-4 xl:p-5 mb-2 md:mb-4"
            >
              <Group key={activity.id} id={activity.id} title="Chargement">
                <span>{user?.name}</span>
                <ul>
                  {deliveries &&
                    deliveries.map(delivery => {
                      const { containers } = delivery;

                      return (
                        <div key={delivery.id} className="mx-4 py-2">
                          {containers &&
                            containers.map(container => (
                              <li
                                key={objectHash({
                                  containerId: container.id,
                                })}
                              >
                                <ContainerItem item={container} />
                              </li>
                            ))}
                        </div>
                      );
                    })}
                </ul>
              </Group>
            </div>
          );
        })}
      </List>
    );
  }

  return <p className="text-center">Aucun chargement pour cette étape.</p>;
};

export default StepLoading;
