import gql from 'graphql-tag';

const placeDetails = gql`
  fragment PlaceDetails on Place {
    id
    formattedAddress
    latitude
    longitude
    name
  }
`;

export default placeDetails;
