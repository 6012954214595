import gql from 'graphql-tag';

import placeDetails from './fragments/details';

const placesCreate = gql`
  mutation placesCreate($data: JSONObject!) {
    placesCreate(data: $data) {
      id
    }
  }
`;

const placesDelete = gql`
  mutation placesDelete($id: GUID!) {
    placesDelete(id: $id) {
      id
    }
  }
`;

const placesSearch = gql`
  mutation placesSearch($hitsPerPage: Int, $q: String!, $spaceId: GUID!) {
    placesSearch(hitsPerPage: $hitsPerPage, q: $q, spaceId: $spaceId) {
      hits {
        ...PlaceDetails
      }
    }
  }
  ${placeDetails}
`;

const placesUpdate = gql`
  mutation placesUpdate($data: JSONObject, $id: GUID!) {
    placesUpdate(data: $data, id: $id) {
      id
    }
  }
`;

export { placesCreate, placesDelete, placesSearch, placesUpdate };
