import classnames from 'classnames';
import React, { FC, ReactElement } from 'react';

import { ListChildrenProps } from '../Body/List';

interface ItemSubProps extends ListChildrenProps {
  className?: string;
  customInfos?: ReactElement;
  icon?: ReactElement;
  title: string | ReactElement;
  subTitle?: string | ReactElement;
  subTitleClassName?: string;
}
const ItemSub: FC<ItemSubProps> = ({
  className,
  children,
  customInfos,
  icon,
  title,
  subTitle,
  subTitleClassName,
}) => (
  <div
    className={classnames('relative mr-6 flex flex-col space-y-6', className)}
  >
    <div className="flex space-x-3">
      {icon && <span>{icon}</span>}
      <div>
        <div className="font-bold text-s">{title}</div>
        {subTitle && (
          <div
            className={classnames(
              'text-s text-neutral-base',
              subTitleClassName,
            )}
          >
            {subTitle}
          </div>
        )}
      </div>
    </div>

    {customInfos && (
      <div className="absolute flex space-x-2 right-6 top-0">{customInfos}</div>
    )}
    {children}
  </div>
);
export default ItemSub;
