import { DataFieldProps } from 'dataformjs';
import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import { GroupBase, Options, OptionsOrGroups } from 'react-select';

import { usersSearch as searchMutation } from '../../../objects/users/mutations';
import UserType from '../../../types/User';
import DataSelect from '../../datas/Select';

interface DataUsersSelectProps extends DataFieldProps {
  formName: string;
  name: string;
  params: {
    spaceId: string;
  };
}

const DataUsersSelect: FC<
  Omit<DataUsersSelectProps, 'componentType'>
> = props => {
  const { formName, params } = props;
  const [searchMutationFunc] = useMutation(searchMutation);

  const handleGetOptionLabel = (option: UserType): string => option?.name ?? '';
  const handleGetOptionValue = (option: UserType): string => option.id;

  const handleIsOptionSelected = (
    option: any,
    optionsSelected: Options<UserType>,
  ): boolean => optionsSelected.some((o: UserType) => o.id === option.id);

  const handleLoadOptions = async (
    inputValue: string,
  ): Promise<OptionsOrGroups<UserType, GroupBase<UserType>>> => {
    let options: UserType[] = [];
    const result = await searchMutationFunc({
      variables: {
        hitsPerPage: 50,
        q: inputValue,
        spaceId: params.spaceId,
      },
    });

    if (
      result &&
      result.data &&
      result.data.usersSearch &&
      result.data.usersSearch.hits
    ) {
      options = result.data.usersSearch.hits;
    }

    return options;
  };

  return (
    <DataSelect
      {...props}
      formName={formName}
      getOptionLabel={handleGetOptionLabel}
      getOptionValue={handleGetOptionValue}
      isOptionSelected={handleIsOptionSelected}
      loadOptions={handleLoadOptions}
    />
  );
};

export default DataUsersSelect;
