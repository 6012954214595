import { DataProps, useSelector } from 'dataformjs';
import keyBy from 'lodash/keyBy';
import objectHash from 'object-hash';
import React, { FC } from 'react';

import ActivityType from '../../../../types/Activity';
import Item from '../../../new/Item';
import ItemSub from '../../../new/Item/Sub';

interface ScenarioActivitiesProps extends DataProps {
  formName: string;
  name: string;
}
const ScenarioActivities: FC<ScenarioActivitiesProps> = ({
  formName,
  name,
}) => {
  const values = useSelector((state: any) => state.form[formName].values);
  const activities: ActivityType[] = values[name];
  const { places } = values;
  const placesLUT = places ? keyBy(places, 'id') : undefined;

  return (
    activities && (
      <div className="flex flex-col space-y-6">
        {activities.map(activity => {
          const { placeId, user, deliveries } = activity;

          return (
            <Item
              key={objectHash({ activityId: activity.id })}
              id={activity.id}
              subTitle={user?.name}
              title={placesLUT && placeId && placesLUT[placeId].name}
            >
              {deliveries && deliveries.length > 0 && (
                <div className="flex flex-col space-y-2">
                  {deliveries.map(delivery => {
                    const { containers } = delivery;

                    return (
                      <ItemSub
                        key={delivery.id}
                        subTitle={`${containers
                          ?.map(container => container.name)
                          .join(', ')}`}
                        title={placesLUT && placesLUT[delivery.placeId].name}
                      />
                    );
                  })}
                </div>
              )}
            </Item>
          );
        })}
      </div>
    )
  );
};

export default ScenarioActivities;
