import { rem } from 'polished';
import React, { FC, SyntheticEvent } from 'react';
import styled from 'styled-components';

import {
  colors,
  radius,
  spacing,
  transition,
} from '../../../../../../styles/theme';
import IconClose from '../../../../../icons/Close';
import ButtonReset from '../../../../ButtonReset';

const Icon = styled(IconClose)`
  display: block;
`;

const ModalContentHeaderButtonCloseSC = styled(ButtonReset)`
  position: absolute;
  top: ${spacing.xxs};
  right: ${spacing.xxs};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(32)};
  width: ${rem(32)};
  color: ${colors.primary.light};
  transition: opacity ${transition.timing.base} ${transition.easing.base};
  border-radius: ${radius.xs};
  background: ${colors.light['300']};
  border: none;
  &:hover,
  &:focus {
    opacity: 0.5;
  }
`;

const ModalContentHeaderButtonClose: FC<{
  className?: string;
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({ className, onClick }) => (
  <ModalContentHeaderButtonCloseSC
    aria-label="Fermer"
    className={className}
    data-dismiss="modal"
    onClick={onClick}
  >
    <Icon />
  </ModalContentHeaderButtonCloseSC>
);

export default ModalContentHeaderButtonClose;
