import { DataProps } from 'dataformjs';
import React, { ReactElement } from 'react';

import DataVehiclesSelect from './Select';

const Data = (
  props: DataProps & {
    formName: string;
    params: { [key: string]: any };
  },
): ReactElement | null => {
  const { componentType, formName, name, params } = props;

  switch (componentType) {
    case 'vehicles/select': {
      if (!name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      if (!params || !params.spaceId) {
        return (
          <div>
            {componentType}: erreur de paramètre : params.spaceId est
            obligatoire
          </div>
        );
      }

      if (!formName) {
        return (
          <div>
            {componentType} : erreur de paramètre : formName est obligatoire
          </div>
        );
      }

      return (
        <DataVehiclesSelect
          {...props}
          formName={formName}
          name={name}
          params={{
            ...params,
            spaceId: params.spaceId,
          }}
        />
      );
    }

    default:
  }

  return null;
};

export default Data;
