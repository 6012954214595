import styled, { css } from 'styled-components';

import { boxShadow, colors, radius, StatusProp } from '../../../styles/theme';

const getTileStatusStyles = (props: StatusProp) => {
  switch (props.status) {
    case 'dark':
      return css`
        background: ${colors.tertiary.base};
        border-color: transparent;
        color: ${colors.light['300']};
      `;

    case 'product':
      return css`
        background: ${colors.tertiary['200']};
        border: none;

        input,
        select,
        textarea {
          background-color: ${colors.light['300']};
        }
      `;

    case 'light':
      return css`
        background: ${colors.light['300']};
        border: none;
      `;

    default:
      return css`
        background: ${colors.tertiary['200']};
        border: none;
      `;
  }
};

const TileSC = styled.div<
  { emphasize?: boolean; muted?: boolean } & StatusProp
>`
  position: relative;
  border-style: solid;
  border-width: 1px;
  box-shadow: ${props => (props.emphasize ? boxShadow.base : 'none')};
  border-radius: ${radius.xs};
  opacity: ${props => (props.muted ? 0.33 : 1)};
  ${props => getTileStatusStyles(props)};
  @media print {
    padding: 0;
    border: none;
  }
`;

export default TileSC;
