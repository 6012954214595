import { DataProps } from 'dataformjs';
import React, { ReactElement } from 'react';

import DataAddressSearch from './Address/Search';
import places from './Places';
import route from './Route';
import scenario from './Scenario';
import step from './Step';
import users from './Users';
import vehicles from './Vehicles';

const Data = (
  props: DataProps & {
    formName: string;
    params: { [key: string]: any };
  },
): ReactElement<any, any> | null => {
  const { componentType, formName, name } = props;
  let result;

  result = places(props);
  if (result) {
    return result;
  }

  result = route(props);
  if (result) {
    return result;
  }

  result = step(props);
  if (result) {
    return result;
  }

  result = scenario(props);
  if (result) {
    return result;
  }

  result = users(props);
  if (result) {
    return result;
  }

  result = vehicles(props);
  if (result) {
    return result;
  }

  switch (componentType) {
    case 'address/search': {
      if (!name) {
        return <div>address : erreur de paramètre : name est obligatoire</div>;
      }

      return <DataAddressSearch {...props} formName={formName} name={name} />;
    }

    default:
  }

  return null;
};

export default Data;
