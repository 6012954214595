import compact from 'lodash/compact';
import keyBy from 'lodash/keyBy';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import PlaceType from '../../types/Place';
import VehicleType from '../../types/Vehicle';
import IconEye from '../icons/Eye';
import IconEyeSlash from '../icons/EyeSlash';
import Item from '../new/Item';

interface VehicleItemProps {
  customInfos?: ReactElement;
  editPathname?: string;
  places?: PlaceType[];
  vehicle: VehicleType;
}

const VehicleItem: FC<VehicleItemProps> = ({
  customInfos,
  editPathname,
  places,
  vehicle,
}) => {
  const { t } = useTranslation();
  const placesLUT = places ? keyBy(places, 'id') : undefined;

  const title = (
    <div className="flex space-x-2 items-center">
      {vehicle.isActive ? <IconEye /> : <IconEyeSlash />}
      <span>{vehicle.user?.name}</span>
      <span className="italic text-light-900 font-light">{vehicle.name}</span>
    </div>
  );
  let subTitle = '';

  if (placesLUT) {
    if (vehicle.departurePlaceId && placesLUT[vehicle.departurePlaceId]) {
      subTitle += `${placesLUT[vehicle.departurePlaceId].name}`;
    }
    if (vehicle.arrivalPlaceId && placesLUT[vehicle.arrivalPlaceId]) {
      subTitle += ` → ${placesLUT[vehicle.arrivalPlaceId].name}`;
    }
  }

  const infos = compact([
    t('vehicle.has-pallet-truck.description', {
      context: vehicle.hasPalletTruck ? 'true' : 'false',
    }),
    t('vehicle.has-hatch.description', {
      context: vehicle.hasHatch ? 'true' : 'false',
    }),
    t('vehicle.is-refrigerated.description', {
      context: vehicle.isRefrigerated ? 'true' : 'false',
    }),
    vehicle.volume
      ? t('vehicle.volume', {
          count: vehicle.volume,
        })
      : t('vehicle.volume-no-specified'),
    vehicle.weight
      ? t('vehicle.weight', {
          count: vehicle.weight,
        })
      : t('vehicle.weight-no-specified'),
  ]);

  return (
    <Item
      customInfos={customInfos}
      editPathname={editPathname}
      id={vehicle.id}
      subTitle={
        <div className="flex flex-col">
          {subTitle}
          <div className="flex space-x-2">
            {infos.map(info => (
              <span key={info as string} className="text-light-900">
                {info}
              </span>
            ))}
          </div>
        </div>
      }
      title={title}
    />
  );
};

export default VehicleItem;
