interface StateProps {
  role?: 'super-admin' | 'admin' | 'editor';
}

const initialState: StateProps = {
  role: undefined,
};

const reducer = (
  state = initialState,
  { type, ...payload }: { type: string; [key: string]: any },
): StateProps => {
  switch (type) {
    case 'SET_USER_ROLE':
      return { ...state, role: payload.role };

    default:
      return state;
  }
};

export default reducer;
