import styled from 'styled-components';

import { colors, font, spacing, transition } from '../../../../styles/theme';

const InputLabel = styled.label`
  display: inline-block;
  cursor: pointer;
  font-weight: ${font.weight.medium};
  color: ${colors.neutral.dark};
  transition: color ${transition.timing.base} ${transition.easing.base};
  padding-bottom: ${spacing.xxs};
  &:hover,
  &:focus {
    color: ${colors.primary.base};
  }
`;

export default InputLabel;
