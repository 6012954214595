import React, { FC, ReactElement, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MODAL_ID } from '../../../../constants';
import { spacing } from '../../../styles/theme';
import ModalContentSC from './Content';
import ModalContentHeader from './Content/Header';
import ModalContentHeaderButtonClose from './Content/Header/ButtonClose';
import ModalContentHeaderTitle from './Content/Header/Title';
import ModalOverlay from './Overlay';

const ModalSC = styled.div<{ show?: boolean }>`
  position: fixed;
  z-index: 666;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: ${spacing.l};
`;

const Modal: FC<{
  className?: string;
  contentClassName?: string;
  onClose: () => void;
  size?: 'large' | 'base';
  title?: string | ReactElement;
}> = ({
  children,
  className,
  contentClassName,
  onClose,
  size = 'base',
  title,
}) => {
  const body = document.querySelector('body');
  const app = document.getElementById('___gatsby');
  const { t } = useTranslation();

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      const keys: { [key: string]: () => void } = {
        '27': () => {
          onClose();
          window.removeEventListener('keyup', handleKeyUp, false);
        },
      };

      if (keys[e.code]) {
        keys[e.code]();
      }
    };

    if (body) {
      body.style.overflowY = 'hidden';
    }
    if (app) {
      app.setAttribute('aria-hidden', 'true');
    }

    window.addEventListener('keyup', handleKeyUp, false);

    return () => {
      if (body) {
        body.style.overflowY = 'scroll';
      }
      if (app) {
        app.setAttribute('aria-hidden', 'false');
      }

      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [app, body, onClose]);

  if (!document.getElementById(MODAL_ID)) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalSC className={className} role="dialog" show tabIndex={-1}>
      <ModalContentSC className={contentClassName}>
        {title && (
          <ModalContentHeader>
            <ModalContentHeaderTitle>
              {'string' === typeof title ? t(title) : title}
            </ModalContentHeaderTitle>
          </ModalContentHeader>
        )}
        <ModalContentHeaderButtonClose onClick={onClose} />
        <div>{children}</div>
      </ModalContentSC>
      <ModalOverlay onClick={onClose} />
    </ModalSC>,
    document.getElementById(MODAL_ID) as Element,
  );
};

export default Modal;
