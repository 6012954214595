import styled from 'styled-components';

const TimelineStepContentBody = styled.div.attrs({
  className: 'pt-4',
})`
  @media print {
    padding: 3mm 0 0;
    display: block;
    overflow: hidden;
    > *:nth-child(1) {
      float: left;
      width: 35%;
      padding-right: 5mm;
    }
    > *:nth-child(2) {
      float: left;
      width: 65%;
    }
  }
`;

export default TimelineStepContentBody;
