import classNames from 'classnames';
import { rem } from 'polished';
import styled, {
  css,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';
import tw from 'twin.macro';

import { DefaultThemeProps, spacing, StatusProp } from '../../styles/theme';

export const ColumnSC = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: ${spacing[4]};
`;

export const ColumnsSC = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 1.5rem;
  }

  > div:last-child {
    margin-right: 0;
  }
`;

const getStatusStyles = ({ status }: StatusProp): any => {
  switch (status) {
    case 'error':
      return css`
        color: ${(props): string => props.theme.colors.danger[500]};
      `;
    case 'warning':
      return css`
        color: ${(props): string => props.theme.colors.warning[500]};
      `;
    default:
      return css`
        color: ${(props): string => props.theme.colors.neutral[500]};
      `;
  }
};

export const FieldMessageSC = styled.div<
  ThemeProps<DefaultThemeProps> & StatusProp
>`
  transform: translateY(${rem(4)});
  font-size: ${(props: ThemeProps<DefaultThemeProps>): string =>
    props.theme.font.size.xs[0]};
  ${(props: StatusProp): FlattenSimpleInterpolation => getStatusStyles(props)};
`;

export const FieldTemplateSC = styled.div`
  // align-items: center;
  // display: flex;
`;

export const FieldWrapperSC = styled.div`
  margin-bottom: ${spacing[4]};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const GroupSC = styled.fieldset.attrs(props => ({
  className: classNames(props.className, 'mb-4'),
}))<ThemeProps<DefaultThemeProps>>`
  border: none;

  legend {
    ${tw`text-xl text-neutral-darker font-bold`};
    margin-bottom: 1rem;
  }

  > p:first-child {
  }
`;

export const GroupDescriptionSC = styled.p.attrs(props => ({
  className: classNames(props.className, 'mb-5'),
}))``;
