import { DataProps, useSelector } from 'dataformjs';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import RouteType from '../../../../types/Route';
import Group from '../../../new/Body/Group';
import List from '../../../new/Body/List';
import Item from '../../../new/Item';

interface ScenarioRoutesProps extends DataProps {
  formName: string;
}

const ScenarioRoutes: FC<ScenarioRoutesProps> = ({ formName }) => {
  const { t } = useTranslation();

  const values: { routes: RouteType[] } = useSelector(
    (state: any) => state.form[formName].values,
  );

  const { routes } = values;
  if (routes.length <= 0) {
    return <p className="text-center">{t('scenario.noEvaluation')}</p>;
  }

  return (
    <List>
      {routes.map((route, index) => {
        const CustomInfos = (
          <>
            {route.distance && (
              <span>{`${Math.round(route.distance / 100) / 10}km`}</span>
            )}
            {route.duration && (
              <span>{`${Math.round(route.duration / 60)}'`}</span>
            )}
            {route.cost && <span>{`${route.cost / 100}€`}</span>}
          </>
        );

        return (
          <Item
            key={route.id}
            customInfos={CustomInfos}
            editPathname="/admin/routes/update/"
            id={route.id}
            title={`Route ${index + 1}`}
          >
            {route.steps && (
              <List>
                {route.steps.map(step => (
                  <Group key={step.id} id={step.id} />
                ))}
              </List>
            )}
          </Item>
        );
      })}
    </List>
  );
};

export default ScenarioRoutes;
