import { rem } from 'polished';
import styled from 'styled-components';

const TimelineStepContentHeader = styled.div`
  padding-top: ${rem(4)};
  @media print {
    padding: 3mm 0;
  }
`;

export default TimelineStepContentHeader;
