import { darken, lighten, rem } from 'polished';
import styled, { css } from 'styled-components';

import {
  colors,
  font,
  radius,
  spacing,
  transition,
} from '../../../styles/theme';
import ButtonReset from '../ButtonReset';
import Link from '../Link';
import { ButtonProps } from './index';

export const ButtonText = styled.span``;

export const IsLoading = styled.div`
  position: absolute;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const getButtonStatusStyles = (props: ButtonProps) => {
  switch (props.status) {
    case 'develop':
      return css`
        background: ${colors.terracota};
        color: ${colors.paleGrey};
        // box-shadow: inset 0 0 0 1px ${colors.neutral.lighter};
        border: 1px solid ${colors.neutral.lighter};
        &:hover,
        &:active {
          background: ${colors.primary.lighter};
          color: ${darken(0.125, colors.primary.base)};
        }
        ${IsLoading} {
          background: ${colors.light['300']};
        }
      `;
    case 'neutral':
      return css`
        background: ${colors.light['300']};
        color: ${colors.primary.base};
        // box-shadow: inset 0 0 0 1px ${colors.neutral.lighter};
        border: 1px solid ${colors.neutral.lighter};
        &:hover {
          background: ${colors.primary.lighter};
          color: ${darken(0.125, colors.primary.base)};
        }
        ${IsLoading} {
          background: ${colors.light['300']};
        }
      `;
    case 'link':
      return css`
        background: transparent;
        color: ${colors.primary.base};
        padding: 0 !important;
        min-height: auto !important;
        line-height: inherit; !important;
        &:hover {
          background: transparent;
          color: ${colors.primary.light};
        }
      `;
    case 'secondary':
      return css`
        background: ${colors.secondary.base};
        color: ${colors.secondary.darker};
        &:hover {
          background: ${colors.secondary.light};
          color: ${colors.secondary.darker};
        }
        ${IsLoading} {
          background: ${colors.secondary.base};
        }
      `;
    case 'tertiary':
      return css`
        background: ${colors.tertiary.light};
        color: ${colors.primary.base};
        &:hover {
          background: ${colors.tertiary.base};
          color: ${colors.primary.base};
        }
        ${IsLoading} {
          background: ${colors.tertiary.light};
        }
      `;
    case 'light':
      return css`
        background: ${colors.light['300']};
        color: ${colors.primary.base};
        // box-shadow: inset 0 0 0 1px ${colors.neutral.lighter};
        border: 1px solid ${colors.neutral.lighter};
        transition: all ${transition.timing.base} ${transition.easing.base};
        &:hover {
          background: ${lighten(0.05, colors.light['300'])};
          color: ${colors.primary.base};
          box-shadow: inset 0 0 0 1px ${colors.neutral.light};
        }
        ${IsLoading} {
          background: ${colors.light['300']};
        }
      `;
    case 'danger':
      return css`
        background: ${colors.danger.base};
        color: ${colors.light['300']};
        &:hover {
          background: ${colors.danger.dark};
          color: ${colors.light};
        }
        ${IsLoading} {
          background: ${colors.danger.base};
        }
      `;
    case 'primaryDark':
      return css`
        background: ${darken(0.05, colors.primary.dark)};
        color: ${colors.light['300']};
        &:hover {
          background: ${darken(0.075, colors.primary.dark)};
          color: ${colors.light['300']};
        }
        ${IsLoading} {
          background: ${darken(0.05, colors.primary.dark)};
        }
      `;
    default:
      return css`
        background: ${colors.primary.base};
        color: ${colors.light['300']};
        &:hover {
          background: ${colors.primary.dark};
          color: ${colors.light['300']};
        }
        ${IsLoading} {
          background: ${colors.primary.base};
        }
      `;
  }
};

const buttonSizeProps = (props: ButtonProps) => {
  switch (props.size) {
    case 's':
    case 'small':
      return css`
        font-size: ${font.size.xs[0]};
        min-height: ${rem(32)};
        padding: ${rem(4)} ${props.square ? 0 : rem(12)};
        min-width: ${props.square ? rem(32) : 'initial'};
        border-radius: ${radius.xs};
        > * + * {
          margin-left: ${rem(6)};
        }
        svg {
          height: ${rem(14)};
          width: ${rem(14)};
        }
      `;

    case 'l':
    case 'big':
      return css`
        min-height: ${rem(48)};
        padding: ${rem(8)} ${props.square ? 0 : rem(24)};
        min-width: ${props.square ? rem(48) : 'initial'};
        border-radius: ${radius.s};
        ${ButtonText} {
          min-width: ${props.large ? rem(100) : 'initial'};
        }
        > * + * {
          margin-left: ${rem(12)};
        }
        svg {
          height: ${rem(16)};
          width: ${rem(16)};
        }
      `;

    case 'primary':
    default:
      return css`
        min-height: ${spacing[8]};
        padding: ${rem(11)} ${props.square ? 0 : rem(16)};
        min-width: ${props.square ? rem(40) : 'initial'};
        border-radius: ${radius.s};
        ${ButtonText} {
          min-width: ${props.large ? rem(100) : 'initial'};
        }
        > * + * {
          margin-left: ${rem(12)};
        }
      `;
  }
};

export const ButtonSC = styled(ButtonReset)<ButtonProps>`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: ${font.weight.medium};
  text-align: center;
  transition: background ${transition.timing.base} ${transition.easing.base},
    color ${transition.timing.base} ${transition.easing.base},
    box-shadow ${transition.timing.base} ${transition.easing.base};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none !important' : 'auto')};

  ${props => getButtonStatusStyles(props)};
  ${props => buttonSizeProps(props)};

  &:focus {
    outline: none;
  }

  @media screen and (-ms-high-contrast: active) {
    border: 2px solid currentcolor;
  }
`;

export const LinkSC = styled(ButtonSC).attrs({ as: Link })`
  text-decoration: none;
`;
