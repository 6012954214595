import styled from 'styled-components';

import { colors, font } from '../../../../../../styles/theme';
import Title from '../../../../Title';

const ModalContentHeaderBoxTitle = styled(Title).attrs({
  size: font.size.xl[0],
})`
  margin: 0;
  color: ${colors.primary.base};
  font-weight: ${font.weight.bold};
`;

export default ModalContentHeaderBoxTitle;
