import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { colors, spacing } from '../../../styles/theme';

export const TabsSC = styled.div``;
export const TabsBarSC = styled.ul.attrs({
  className: 'flex border-b border-neutral-200 mb-6',
})``;

export const TabsBarItemSC = styled.li.attrs(props => ({
  className: classnames(props.className, 'mr-1'),
}))`
  margin: 0;

  button {
    background-color: transparent;
    padding: ${spacing[2]} ${spacing[4]};
    border-color: transparent;
    color: ${colors.neutral[400]};
    font-weight: 600;
    border-bottom-width: 2px;

    transform: translateY(1px);
    outline: none;

    &:hover {
      color: ${colors.neutral[700]};
    }
  }

  &.is-active {
    margin-bottom: -1px !important;
    button {
      border-color: ${colors.neutral.dark};
      color: ${colors.neutral[800]};
    }
  }
`;

export interface TabProps {
  component: JSX.Element;
  name: string;
}

export type TabsProps = {
  className?: string;
  values: TabProps[];
};

const Tabs: FC<TabsProps> = ({ className, values }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [params, setParams] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    let tab = 0;
    const search: { [key: string]: any } = {};
    if (window && window.location && window.location.search) {
      window.location.search
        .slice(1)
        .split('&')
        .forEach(option => {
          const [key, value] = option.split('=');
          search[key] = value;
        });
      if (search.tab) {
        tab = parseInt(search.tab, 10);
      }
    }
    setActiveTab(tab);
    setParams(search);
  }, []);

  const handleChangeTab = (e: React.MouseEvent): void => {
    const tab = parseInt(e.currentTarget.getAttribute('data-index') || '0', 10);
    setActiveTab(tab);

    let location = window && window.location ? window.location.pathname : '/';
    params.tab = tab;
    location += `?${Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&')}`;

    window.history.replaceState({ location, tab }, `tab ${tab}`, location);
  };

  return (
    <TabsSC className={className}>
      <TabsBarSC>
        {values.map(({ name }, index) => (
          <TabsBarItemSC
            key={name}
            className={activeTab === index ? 'is-active' : ''}
          >
            <button data-index={index} onClick={handleChangeTab} type="button">
              {t(name)}
            </button>
          </TabsBarItemSC>
        ))}
      </TabsBarSC>
      <div>{values[activeTab].component}</div>
    </TabsSC>
  );
};

export default Tabs;
