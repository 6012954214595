import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import appFr from './fr.json';
import mailsFr from './mails/fr.json';
import smsFr from './sms/fr.json';

// eslint-disable-next-line import/prefer-default-export
export const fr = {
  translation: {
    ...appFr,
    mails: mailsFr,
    sms: smsFr,
  },
};

const resources = { fr };

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: 'development' === process.env.NODE_ENV,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: '.',
    lng: 'fr',
    simplifyPluralSuffix: false,
  });

// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     resources,
//     debug: true,
//     defaultNS: 'localized-strings',
//     fallbackLng: 'fr',
//     interpolation: {
//       escapeValue: false,
//     },
//     keySeparator: '/',
//     lng: 'fr',
//   });

export default i18n;
