import React, { FC } from 'react';
import styled from 'styled-components';

import { spacing } from '../../../styles/theme';

interface ElementsSpacerProps {
  alignItems?: 'flex-start' | 'flex-end' | 'center';
  className?: string;
  spacingSize?: string;
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
}
const ElementsSpacerSC = styled.div<ElementsSpacerProps>`
  display: flex;
  margin: -${props => props.spacingSize};
  flex-wrap: wrap;
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  > * {
    margin: ${props => props.spacingSize};
  }
`;

/**
 * ElementsSpacer
 *
 * @param {object} props
 * @param {'flex-start' | 'flex-end' | 'center'} props.alignItems
 * @param {string} props.className
 * @param {React.ReactNode} props.children
 * @param {string} props.spacingSize
 * @param {'flex-start'|'flex-end'|'center'|'space-between'|'space-around'|'space-evenly'} props.justifyContent
 * @returns {React.ReactElement}
 */
const ElementsSpacer: FC<ElementsSpacerProps> = ({
  alignItems = 'center',
  children,
  className,
  spacingSize = spacing.xxs,
  justifyContent = 'flex-start',
}) => (
  <ElementsSpacerSC
    alignItems={alignItems}
    className={className}
    justifyContent={justifyContent}
    spacingSize={spacingSize}
  >
    {children}
  </ElementsSpacerSC>
);

export default ElementsSpacer;
