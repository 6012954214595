/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
import { DefaultTheme } from 'styled-components';

import boxShadow from './boxShadow';
import colors from './colors';
import control from './control';
import font from './font';
import radius from './radius';
import screens from './screens';
import spacing from './spacing';

export interface StatusProp {
  status?:
    | 'dark'
    | 'danger'
    | 'develop'
    | 'error'
    | 'info'
    | 'light'
    | 'link'
    | 'neutral'
    | 'primary'
    | 'primaryDark'
    | 'product'
    | 'quaternary'
    | 'secondary'
    | 'success'
    | 'tertiary'
    | 'warning';
}

export interface DefaultThemeProps extends DefaultTheme {
  borderRadius: string;
  colors: {
    light: {
      50?: string;
      100?: string;
      200?: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    dark: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    neutral: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
      lightest: string;
      lighter: string;
      light: string;
      base: string;
      dark: string;
      darker: string;
    };
    primary: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
      lightest: string;
      lighter: string;
      light: string;
      base: string;
      dark: string;
      darker: string;
    };
    secondary: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
      lighter: string;
      light: string;
      base: string;
      dark: string;
      darker: string;
    };
    tertiary: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
      lightest: string;
      lighter: string;
      light: string;
      base: string;
      dark: string;
      darker: string;
    };
    quaternary: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    success: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
      lighter: string;
      light: string;
      base: string;
      dark: string;
      darker: string;
    };
    info: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
      lighter: string;
      light: string;
      base: string;
      dark: string;
      darker: string;
    };
    warning: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
      lighter: string;
      light: string;
      base: string;
      dark: string;
      darker: string;
    };
    danger: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
      lighter: string;
      light: string;
      base: string;
      dark: string;
      darker: string;
    };
    logo: {
      primary: string;
      secondary: string;
    };
    print: {
      base: string;
      light: string;
    };
    lightAlternate: string;
    blue: string;
    darkGreyBlue: string;
    paleGrey: string;
    sunYellow: string;
    terracota: string;
    darkTerracota: string;
    yellowGreen: string;
  };
  control: any;
  font: any;
  screens: { [key: string]: string };
  spacing: { [key: string]: string };
}

const theme: DefaultThemeProps = {
  borderRadius: '5px',
  colors,
  control,
  font,
  screens,
  spacing,
};

export const lineHeight = {
  xs: 1,
  s: 1.15,
  m: 1.25,
  l: 1.5,
  xl: 1.625,
};

export const transition = {
  timing: {
    base: '0.3s',
    fast: '0.15s',
  },
  easing: {
    base: 'ease-in-out',
  },
};

export const gutter = {
  size: {
    xxxl: 48,
    xxl: 40,
    xl: 32,
    l: 16,
    m: 8,
  },
};

export const container = {
  size: {
    m: 1000,
  },
};

export const header = {
  height: {
    base: 80,
    small: 64,
  },
};

/**
 * mqUp
 *
 * @param {string} size
 * @returns {string}
 */

export const mediaQueryMin = (size: string): string =>
  `@media (min-width: ${size})`;
/**
 * mqDown
 *
 * @param {string} size
 * @returns {string}
 */
export const mediaQueryMax = (size: string): string =>
  `@media (max-width: ${size})`;

export default theme;
export { boxShadow, colors, control, font, radius, screens, spacing };

// import { em, rem, rgba } from 'polished';
//
//

//
// export const lineHeight = {
//   xs: 1,
//   s: 1.15,
//   m: 1.25,
//   l: 1.5,
//   xl: 1.625,
// };
//
// export const transition = {
//   timing: {
//     base: '0.3s',
//     fast: '0.15s',
//   },
//   easing: {
//     base: 'ease-in-out',
//   },
// };
//
// export const gutter = {
//   size: {
//     xxxl: 48,
//     xxl: 40,
//     xl: 32,
//     l: 16,
//     m: 8,
//   },
// };
//
// export const container = {
//   size: {
//     m: 1000,
//   },
// };
//
// export const Header = {
//   height: {
//     base: 80,
//     small: 64,
//   },
// };
//
// export const breakpoint = {
//   smartphone: 480,
//   tablet: 768,
//   desktop: 1024,
//   bigDesktop: 1300,
//   ultraWide: 2000,
//   mainNav: 900,
// };
//
// /**
//  * mqUp
//  *
//  * @param {number} size
//  * @returns {string}
//  */
//
// export const mediaQueryMin = (size: number): string =>
//   `@media (min-width: ${em(size)})`;
// /**
//  * mqDown
//  *
//  * @param {number} size
//  * @returns {string}
//  */
// export const mediaQueryMax = (size: number): string =>
//   `@media (max-width: ${em(size + -1)})`;
//
// export const boxShadow = {
//   alternate: `0 ${rem(3)} ${rem(16)} 0 ${rgba(color.primary.darker, 0.05)}`,
//   base: `0 ${rem(3)} ${rem(16)} 0 ${rgba(color.neutral.darker, 0.05)}`,
//   big: `0 ${rem(3)} ${rem(16)} 0 ${rgba(color.neutral.darker, 0.1)}`,
// };
