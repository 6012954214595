import { Icon } from 'dataformjs';
import React, { ComponentType, FC, ReactNode, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { StatusProp } from '../../../styles/theme';
import IconLoading from '../../icons/Loading';
import { ButtonSC, ButtonText, LinkSC } from './styles';

export interface ButtonProps extends StatusProp {
  as?: ComponentType;
  className?: string;
  datas?: any;
  disabled?: boolean;
  iconColor?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  iconSize?: number;
  id?: string;
  large?: boolean;
  loading?: boolean | string;
  name?: string;
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => any;
  size?: string;
  square?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top' | string;
  title?: string;
  to?: string;
  type?: 'button' | 'submit' | 'reset';
  text?: string;
}

const Button: FC<ButtonProps> = ({
  className,
  children,
  datas,
  disabled = false,
  iconColor,
  iconLeft,
  iconRight,
  iconSize,
  id,
  large = false,
  loading = false,
  name,
  onClick,
  size = 'base',
  square,
  status,
  target,
  title,
  to,
  type,
  text,
}) => {
  const { t } = useTranslation();

  if (to) {
    return (
      <LinkSC
        className={className}
        disabled={disabled}
        id={id}
        large={large}
        loading={loading ? 'true' : 'false'}
        size={size}
        square={square}
        status={status}
        target={target}
        title={title}
        to={to}
        type={type}
      >
        {iconLeft && <Icon value={iconLeft} />}
        {text && (
          <ButtonText
            className={
              (iconLeft && 'text-left') || (iconRight && 'text-left') || ''
            }
          >
            {t(text)}
          </ButtonText>
        )}
        {children && (
          <ButtonText
            className={
              (iconLeft && 'text-left') || (iconRight && 'text-left') || ''
            }
          >
            {children}
          </ButtonText>
        )}
        {iconRight && !loading && <Icon value={iconRight} />}
        {loading && <Icon value={IconLoading} />}
      </LinkSC>
    );
  }

  return (
    <ButtonSC
      className={className}
      disabled={disabled}
      id={id}
      large={large}
      loading={loading ? 'true' : 'false'}
      name={name}
      onClick={onClick}
      size={size}
      square={square}
      status={status}
      title={title}
      type={type}
      {...datas}
    >
      {iconLeft && <Icon color={iconColor} size={iconSize} value={iconLeft} />}
      {text && (
        <ButtonText
          className={
            (iconLeft && 'text-left') || (iconRight && 'text-left') || ''
          }
        >
          {t(text)}
        </ButtonText>
      )}
      {children && (
        <ButtonText
          className={
            (iconLeft && 'text-left') || (iconRight && 'text-left') || ''
          }
        >
          {children}
        </ButtonText>
      )}
      {iconRight && !loading && (
        <Icon color={iconColor} size={iconSize} value={iconRight} />
      )}
      {loading && (
        <Icon color={iconColor} size={iconSize} value={IconLoading} />
      )}
    </ButtonSC>
  );
};

export default Button;
