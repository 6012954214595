import { DataProps, useSelector } from 'dataformjs';
import objectHash from 'object-hash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import UserType from '../../../../types/User';
import List from '../../../new/Body/List';
import Item from '../../../new/Item';

interface ScenarioUsersProps extends DataProps {
  formName: string;
  name: string;
}
const ScenarioUsers: FC<ScenarioUsersProps> = ({ formName, name }) => {
  const { t } = useTranslation();

  console.info('ScenarioUsers');
  const values = useSelector((state: any) => state.form[formName].values);
  const users: {
    id: string;
    cost?: number;
    distance?: number;
    duration?: number;
    user: UserType;
  }[] = values.scenarioUsers;

  if (users.length <= 0) {
    return <p className="text-center">{t('scenario.noEstimation')}</p>;
  }

  return (
    <List>
      {users?.map(user => {
        const CustomInfos = (
          <>
            {user.distance && (
              <span>
                {`${Math.round(user.distance / 100) / 10}`}
                km
              </span>
            )}
            {user.duration && (
              <span>{`${Math.round(user.duration / 60)}'`}</span>
            )}
            {user.cost && <span>{`${user.cost / 100}€`}</span>}
          </>
        );

        return (
          <Item
            key={objectHash({ userId: user.id })}
            customInfos={CustomInfos}
            id={user.id}
            title={user.user.name}
          />
        );
      })}
    </List>
  );
};

export default ScenarioUsers;
