import React, { FC, ReactNode } from 'react';

import { StatusProp } from '../../../styles/theme';
import TileHeadTitle from './Head';
import TileSC from './styles';

interface TileProps extends StatusProp {
  className?: string;
  emphasize?: boolean;
  id?: string;
  header?: {
    actions?: ReactNode;
    infos?: ReactNode;
    subTitle?: string;
    title?: string;
  };
  muted?: boolean;
  size?: 'small' | 'big';
}
/**
 * TileHead
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.className
 * @param {boolean} props.emphasize
 * @param {object} props.header
 * @param {boolean} props.muted
 * @param {string} props.size
 * @param {string} props.status
 * @returns {React.ReactNode}
 */

const Tile: FC<TileProps> = ({
  children,
  className,
  emphasize = false,
  id,
  header,
  muted = false,
  size = 'big',
  status,
}) => (
  <TileSC
    className={`px-2 py-3 ${'big' === size && 'md:p-4 xl:p-5'} ${className}`}
    emphasize={emphasize}
    id={id}
    muted={muted}
    status={status}
  >
    {header && header.title && (
      <TileHeadTitle
        actions={header.actions}
        infos={header.infos}
        size={size}
        subTitle={header.subTitle}
        title={header.title}
      />
    )}
    {children}
  </TileSC>
);

export default Tile;
