import styled from 'styled-components';

const TimelineStepContent = styled.div`
  flex-grow: 1;
  @media print {
    padding: 0;
  }
`;

export default TimelineStepContent;
