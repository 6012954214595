import objectHash from 'object-hash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PlaceType from '../../../../../types/Place';
import UserType from '../../../../../types/User';
import Tile from '../../../../base/Tile';

const Delivery: FC<{
  place: PlaceType;
  user?: UserType;
}> = ({ place, user }) => {
  const { t } = useTranslation();

  const Infos = () => (
    <div>
      {user && user.name && (
        <p>{`${t('route.deliveries.from')} ${user.name}`}</p>
      )}

      {/* {contact && <p>{`${t('contactPoint.contactOnSite')} : ${contact}`}</p>}
      {comments && <p>{`${t('contactPoint.comments')} : ${comments}`}</p>} */}
    </div>
  );

  return (
    <Tile
      key={objectHash({ placeId: place.id })}
      className="mb-2 md:mb-4"
      header={{
        infos: <Infos />,
        title: place && place.name,
      }}
      status="product"
    />
  );
};
export default Delivery;
