import { DataFieldProps, Field } from 'dataformjs';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionMeta,
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  OnChangeValue,
  Options,
  OptionsOrGroups,
} from 'react-select';

import LabelSC from '../../base/Input/Label';
import { FieldWrapperSC } from '../../base/styles';
import DataSelectRender from './Render';

export interface DataFieldAsyncSelectProps<Option, IsMulti extends boolean>
  extends DataFieldProps {
  formatOptionLabel?: (data: Option, formatOptionLabelMeta: any) => ReactNode;
  formName: string;
  name: string;
  getOptionLabel?: GetOptionLabel<Option>;
  getOptionValue?: GetOptionValue<Option>;
  isOptionSelected: (option: Option, selectValue: Options<Option>) => boolean;
  loadingMessage?: (obj: { inputValue: string }) => ReactNode;
  loadOptions:
    | ((
        inputValue: string,
        callback: (options: OptionsOrGroups<Option, GroupBase<Option>>) => void,
      ) => void)
    | ((
        inputValue: string,
      ) => Promise<OptionsOrGroups<Option, GroupBase<Option>>>);
  noOptionsMessage?: (obj: { inputValue: string }) => ReactNode;
  onChangeValue?: (
    newValue: OnChangeValue<Option, IsMulti>,
    actionMeta: ActionMeta<Option>,
  ) => void;
}

const DataSelect: FC<
  Omit<DataFieldAsyncSelectProps<any, boolean>, 'componentType'>
> = ({
  customBottom,
  customBottomProps,
  customInfos,
  customInfosProps,
  customTop,
  customTopProps,
  label,
  labelShow = true,
  wrapperProps,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <FieldWrapperSC {...wrapperProps}>
      {customInfos && <div {...customInfosProps}>{customInfos}</div>}
      {customTop && <div {...customTopProps}>{customTop}</div>}
      {labelShow && <LabelSC>{label ? t(label) : label}</LabelSC>}

      <Field {...props} component={DataSelectRender} />
      {customBottom && <div {...customBottomProps}>{customBottom}</div>}
    </FieldWrapperSC>
  );
};

export default DataSelect;
