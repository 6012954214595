import { UserCredential } from '@firebase/auth';
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import firebase from 'firebase/compat';
import { navigate } from 'gatsby';

import { auth } from '../firebase';

class User {
  static getToken = (user: firebase.User): Promise<boolean> =>
    user
      .getIdTokenResult(true)
      .then((idTokenResult: firebase.auth.IdTokenResult) => {
        User.login(idTokenResult.token);

        return idTokenResult.claims?.role;
      })
      .catch((error: Error) => {
        console.error(error.message);
        User.logout();

        return false;
      });

  static sendPasswordResetEmail = (email: string): Promise<void> =>
    sendPasswordResetEmail(auth, email);

  static signInWithEmailAndPassword = (
    email: string,
    password: string,
  ): Promise<UserCredential> =>
    signInWithEmailAndPassword(auth, email, password);

  static login = (token: string): void => {
    if ('undefined' !== typeof localStorage) {
      localStorage.setItem('token', token);
    }
  };

  static logout = (): Promise<void> =>
    auth
      .signOut()
      .then(() => {
        if ('undefined' !== typeof localStorage) {
          localStorage.removeItem('token');
        }
        console.info('logout');

        return navigate(`/login/`);
      })
      .catch(error => {
        console.error(error.message);
      });
}

export default User;
