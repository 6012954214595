import gql from 'graphql-tag';

export const addressesAutocomplete = gql`
  mutation addressesAutocomplete($address: String!, $session: String!) {
    addressesAutocomplete(address: $address, session: $session)
  }
`;

export const addressesDetails = gql`
  mutation addressesDetails($placeId: String!) {
    addressesDetails(placeId: $placeId)
  }
`;

export const addressesFindplacefromtext = gql`
  mutation addressesFindplacefromtext($address: String!) {
    addressesFindplacefromtext(address: $address)
  }
`;
