import { DataProps } from 'dataformjs';
import React, { ReactElement } from 'react';

import ScenarioActivities from './Activities';
import ScenarioRoutes from './Routes';
import ScenarioUsers from './Users';
import ScenarioVehicles from './Vehicles';

const Data = (
  props: DataProps & {
    formName: string;
    params: { [key: string]: any };
  },
): ReactElement<any, any> | null => {
  const { componentType, formName, formValues, name } = props;

  switch (componentType) {
    case 'scenario/activities': {
      if (!name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <ScenarioActivities
          {...props}
          formName={formName}
          formValues={formValues}
          name={name}
        />
      );
    }

    case 'scenario/routes': {
      if (!name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <ScenarioRoutes
          {...props}
          formName={formName}
          formValues={formValues}
          name={name}
        />
      );
    }

    case 'scenario/users': {
      if (!name) {
        return (
          <div>
            {componentType} : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <ScenarioUsers
          {...props}
          formName={formName}
          formValues={formValues}
          name={name}
        />
      );
    }

    case 'scenario/vehicles': {
      return (
        <ScenarioVehicles
          {...props}
          formName={formName}
          formValues={formValues}
          name={name}
        />
      );
    }

    default:
  }

  return null;
};

export default Data;
