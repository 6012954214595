import styled from 'styled-components';

const ButtonReset = styled.button`
  border: none;
  background-color: transparent;
  font-family: inherit;
  padding: 0;
  line-height: 1;
  cursor: pointer;

  @media screen and (-ms-high-contrast: active) {
    border: 2px solid currentcolor;
  }
`;

export default ButtonReset;
