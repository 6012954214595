/* eslint-disable max-len */
import React, { FC } from 'react';

import IconProps from '../props';

const IconBack: FC<IconProps> = ({ color, size = 16 }) => (
  <svg height={size} viewBox="0 0 16 16" width={size}>
    <path
      d="M0.012001 8.01072V8.01074C0.0131872 7.55165 0.210652 7.11498 0.554588 6.81088L8.00631 0.252379V0.252379C8.41651 -0.0834227 9.02128 -0.0231043 9.35707 0.387103C9.67637 0.777152 9.63957 1.34773 9.27281 1.69353L3.51554 6.75955V6.75955C3.4467 6.82064 3.44041 6.92596 3.50149 6.9948C3.53298 7.03029 3.57809 7.05067 3.62553 7.05085H15.0334H15.0334C15.5635 7.05085 15.9933 7.4806 15.9933 8.01073C15.9933 8.54085 15.5635 8.9706 15.0334 8.9706H3.62753V8.9706C3.53513 8.97149 3.46094 9.04712 3.46183 9.13952C3.46228 9.18654 3.4825 9.2312 3.51754 9.26257L9.27481 14.3286V14.3286C9.68721 14.6617 9.7515 15.266 9.4184 15.6784C9.08529 16.0908 8.48094 16.1551 8.06854 15.822C8.04773 15.8052 8.02764 15.7876 8.0083 15.7691L0.555253 9.21057V9.21057C0.210927 8.9067 0.0131774 8.46996 0.0119895 8.01072L0.012001 8.01072Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconBack;
