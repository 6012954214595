import classnames from 'classnames';
import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

import IconProps from '../props';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1,150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90,150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90,150;
    stroke-dashoffset: -124;
  }
`;

const Svg = styled.svg`
  animation: ${rotate} 2s linear infinite;
`;

const Circle = styled.circle`
  stroke-dasharray: 1, 150;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: ${dash} 1.5s ease-in-out infinite;
`;

/* eslint-disable max-len */

const IconLoading: FC<IconProps> = ({ className, color, size = 16 }) => (
  <Svg
    className={classnames(className, 'no-print')}
    height={size}
    viewBox="0 0 52 52"
    width={size}
  >
    <Circle
      className="path"
      cx="26px"
      cy="26px"
      fill="none"
      r="20px"
      stroke={color || 'currentColor'}
      strokeWidth="4px"
    />
  </Svg>
);

export default IconLoading;
