import { rem } from 'polished';
import styled, { keyframes } from 'styled-components';

import { colors, radius, transition } from '../../../../styles/theme';

const FadeInAnimation = keyframes`  
  from { opacity: 0; }
  to { opacity: 1; }
`;

const ModalContentSC = styled.div`
  z-index: 1;
  position: relative;
  background: ${colors.light['300']};
  border-radius: ${radius.xs};
  max-width: ${rem(720)};
  width: 100%;
  max-height: 100%;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  overflow: auto;
  animation: ${FadeInAnimation} ${transition.easing.base}
    ${transition.timing.fast};
`;

export default ModalContentSC;
