import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { colors, spacing } from '../../../styles/theme';
import ElementsSpacer from '../ElementsSpacer';

const RecapRowEl = styled.li<{ borderTop: boolean; borderBottom: boolean }>`
  border-top: ${props =>
    props.borderTop ? `1px solid ${colors.neutral.lighter}` : 'none'};
  border-bottom: ${props =>
    props.borderBottom ? `1px solid ${colors.neutral.lighter}` : 'none'};
  @media print {
    padding: 0;
    border-top: none;
    border-bottom: none;
  }
`;

const Right = styled.div`
  @media print {
    display: block;
    padding-left: 6mm;
  }
`;

/**
 * RecapRow
 *
 * @param {object} props
 * @param {boolean} props.borderBottom
 * @param {boolean} props.borderTop
 * @param {string} props.className
 * @param {React.ReactNode} props.icon
 * @param {React.ReactNode} props.leftChildren
 * @param {React.ReactNode} props.rightChildren
 * @returns {React.ReactElement}
 */
const RecapRow: FC<{
  borderBottom?: boolean;
  borderTop?: boolean;
  className?: string;
  icon?: ReactNode;
  leftChildren?: ReactNode;
  rightChildren?: ReactNode;
}> = ({
  borderBottom = false,
  borderTop = false,
  className,
  icon,
  leftChildren,
  rightChildren,
}) => {
  const Icon: any = icon;

  return (
    <RecapRowEl
      borderBottom={borderBottom}
      borderTop={borderTop}
      className={`${className} py-1 md:py-2`}
    >
      <div className="md:flex md:justify-between">
        <div>
          <ElementsSpacer spacingSize={spacing.xxs}>
            {'function' === typeof icon ? <Icon /> : icon}
            <div>{leftChildren}</div>
          </ElementsSpacer>
        </div>
        {rightChildren && (
          <Right className="pl-5 md:pl-4">
            <strong>{rightChildren}</strong>
          </Right>
        )}
      </div>
    </RecapRowEl>
  );
};

export default RecapRow;
