import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { font, lineHeight, spacing } from '../../../../styles/theme';
import Title from '../../Title';

interface TileHeaderProps {
  actions?: ReactNode;
  infos?: ReactNode;
  title?: string;
  size?: 'small' | 'big';
  subTitle?: string;
}
const TileHeadEl = styled.div<TileHeaderProps>`
  span {
    font-size: 85%;
    font-weight: ${font.weight.base};
  }
  &:not(:last-child) {
    margin-bottom: ${props =>
      'small' === props.size ? spacing.xxs : spacing.s};
  }
`;

const TileHeadGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TileHeadTitle = styled(Title)`
  line-height: ${lineHeight.s};
`;

const TileHeadActions = styled.div`
  margin-left: ${spacing.xs};
  flex-shrink: 0;
`;

/**
 * TileHead
 *
 * @param {object} props
 * @param {React.ReactNode} props.actions
 * @param {React.ReactNode} props.infos
 * @param {string} props.size
 * @param {string} props.subTitle
 * @param {string} props.title
 * @returns {React.ReactElement}
 */
const TileHead: FC<TileHeaderProps> = ({
  actions,
  infos,
  title,
  size = 'big',
  subTitle,
}) => (
  <TileHeadEl size={size}>
    {title && (
      <TileHeadGrid>
        <div>
          <TileHeadTitle
            size={'small' === size ? font.size.m[0] : font.size.l[0]}
          >
            {title}
            {subTitle && <span>{` - ${subTitle}`}</span>}
          </TileHeadTitle>
          {infos && (
            <div className={'small' === size ? 'mt-1' : 'mt-2'}>{infos}</div>
          )}
        </div>
        {actions && <TileHeadActions>{actions}</TileHeadActions>}
      </TileHeadGrid>
    )}
  </TileHeadEl>
);

export default TileHead;
