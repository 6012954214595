import React, { FC, ReactElement, useState } from 'react';

import Button from '../../base/Button';
import IconEdit from '../../icons/Edit';
import Modal from './index';

interface ModalOpenProps {
  id: string;
  modal: ReactElement;
  title?: string | ReactElement;
}
const ModalOpen: FC<ModalOpenProps> = ({ id, modal, title }) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const handleEditOnClick = () => {
    console.info('handleEditOnClick');
    setShowForm(true);
  };

  const handleOnClose = () => {
    setShowForm(false);
  };

  return (
    <>
      {showForm && (
        <Modal
          contentClassName="min-h-screen-1/2"
          onClose={handleOnClose}
          title={title}
        >
          {modal}
        </Modal>
      )}
      <Button
        className="text-light-900"
        datas={{ 'data-id': id }}
        iconLeft={IconEdit}
        onClick={handleEditOnClick}
        size="small"
        status="link"
      />
    </>
  );
};

export default ModalOpen;
