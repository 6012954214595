/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */

module.exports = {
  light: {
    50: '#ffffff',
    100: '#fefefe',
    200: '#fefefe',
    300: '#fdfdfd',
    400: '#fbfbfb',
    500: '#f9f9f9',
    600: '#e0e0e0',
    700: '#bbbbbb',
    800: '#959595',
    900: '#7a7a7a',
  },
  dark: {
    50: '#f4f4f4',
    100: '#e8e8e9',
    200: '#c6c7c8',
    300: '#a4a5a6',
    400: '#5f6164',
    500: '#1b1d21',
    600: '#181a1e',
    700: '#141619',
    800: '#101114',
    900: '#0d0e10',
  },
  waterloo: {
    50: '#f9f9fa',
    100: '#f3f3f5',
    200: '#e0e1e7',
    300: '#cdcfd9',
    400: '#a8acbc',
    500: '#83889f',
    600: '#767a8f',
    700: '#626677',
    800: '#4f525f',
    900: '#40434e',
  },
  neutral: {
    50: '#f3f4f7',
    100: '#e7e9ee',
    200: '#c4c8d5',
    300: '#a0a7bb',
    400: '#586488',
    500: '#112255',
    600: '#0f1f4d',
    700: '#0d1a40',
    800: '#0a1433',
    900: '#08112a',
    lightest: 'hsla(225, 67%, 13%, .05)',
    lighter: 'hsla(225, 67%, 13%, .075)',
    light: 'hsla(225, 67%, 13%, .25)',
    base: 'hsla(225,67%,13%,0.75)',
    dark: 'hsla(225, 67%, 13%, .85)',
    darker: 'hsla(225, 67%, 13%, 1)',
  },
  primary: {
    50: '#f8f5fc',
    100: '#f0ebf8',
    200: '#daceee',
    300: '#c3b1e3',
    400: '#9776ce',
    500: '#6a3bb9',
    600: '#5f35a7',
    700: '#502c8b',
    800: '#40236f',
    900: '#341d5b',
    lightest: '#f8f9fd',
    lighter: '#e1d3f9',
    light: '#9878db',
    base: '#6a3bb9',
    dark: '#481c92',
    darker: '#231161',
  },
  secondary: {
    50: '#fafcf7',
    100: '#f6faef',
    200: '#e8f2d6',
    300: '#daebbe',
    400: '#bedb8d',
    500: '#a2cc5c',
    600: '#92b853',
    700: '#7a9945',
    800: '#617a37',
    900: '#4f642d',
    lighter: '#F6FCE0',
    light: '#d8efa1',
    base: '#a2cc5c',
    dark: '#64922e',
    darker: '#366111',
  },
  tertiary: {
    50: '#fdfdfd',
    100: '#fafafa',
    200: '#f3f3f3',
    300: '#ececec',
    400: '#dedede',
    500: '#d0d0d0',
    600: '#bbbbbb',
    700: '#9c9c9c',
    800: '#7d7d7d',
    900: '#666666',
    lightest: '#fafafa',
    lighter: '#f1f1f1',
    light: '#eaeaea',
    base: '#d0d0d0',
    dark: '#828282',
    darker: '#474747',
  },
  quaternary: {
    50: '#fffafc',
    100: '#fff6f9',
    200: '#ffe8ef',
    300: '#ffdae5',
    400: '#ffbed2',
    500: '#ffa2bf',
    600: '#e692ac',
    700: '#bf7a8f',
    800: '#996173',
    900: '#7d4f5e',
  },
  success: {
    50: '#f4faf4',
    100: '#e8f4e8',
    200: '#c7e4c7',
    300: '#a5d4a5',
    400: '#61b361',
    500: '#1d931d',
    600: '#1a841a',
    700: '#166e16',
    800: '#115811',
    900: '#0e480e',
    lighter: '#DFF9D1',
    light: '#85DE73',
    base: '#1D931D',
    dark: '#0E691E',
    darker: '#05461c',
  },
  info: {
    50: '#f2f8fd',
    100: '#e6f2fb',
    200: '#c0def5',
    300: '#9bcaef',
    400: '#4fa3e2',
    500: '#047bd6',
    600: '#046fc1',
    700: '#035ca1',
    800: '#024a80',
    900: '#023c69',
    lighter: '#CBF2FC',
    light: '#63C4F2',
    base: '#047BD6',
    dark: '#02479A',
    darker: '#002366',
  },
  warning: {
    50: '#fffcf2',
    100: '#fffae6',
    200: '#fff2bf',
    300: '#ffeb99',
    400: '#ffdb4d',
    500: '#ffcc00',
    600: '#e6b800',
    700: '#bf9900',
    800: '#997a00',
    900: '#7d6400',
    lighter: '#FFF9CC',
    light: '#FFE666',
    base: '#FFCC00',
    dark: '#B78B00',
    darker: '#7a5700',
  },
  danger: {
    50: '#fdf5f4',
    100: '#faece9',
    200: '#f3cfc8',
    300: '#ebb3a6',
    400: '#dd7964',
    500: '#ce4021',
    600: '#b93a1e',
    700: '#9b3019',
    800: '#7c2614',
    900: '#651f10',
    lighter: '#FCE6D2',
    light: '#F09F77',
    base: '#CE4021',
    dark: '#94230A',
    darker: '#620611',
  },

  logo: {
    primary: '#2c2661',
    secondary: '#b4c348',
  },

  print: {
    base: '#000',
    light: '#ddd',
  },
  lightAlternate: '#f1f1f1',
  blue: '#206cdc',
  darkGreyBlue: '#35425b',
  paleGrey: '#f0f4f8',
  sunYellow: '#ffd422',
  terracota: '#e46b2a',
  darkTerracota: '#bd5c28',
  yellowGreen: '#a7e521',
  lightGrey: '#cacccd',
  darkGrey: '#82888a',
  transparent: 'transparent',
  current: 'currentColor',
};
