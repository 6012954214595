import { DataProps, useSelector } from 'dataformjs';
import objectHash from 'object-hash';
import React, { FC } from 'react';

import RouteType from '../../../../types/Route';
import Steps from './Steps';

interface RouteRoadmapProps extends DataProps {
  formName: string;
}
const RouteRoadmap: FC<RouteRoadmapProps> = ({ formName }) => {
  const values: RouteType = useSelector(
    (state: any) => state.form[formName].values,
  );

  return (
    <Steps
      key={objectHash({ routeId: values.id })}
      formName={formName}
      route={values}
    />
  );
};

export default RouteRoadmap;
