import gql from 'graphql-tag';

const usersCreate = gql`
  mutation usersCreate(
    $address: String
    $companyName: String
    $email: String
    $name: String!
    $phone: String
    $spaceId: GUID!
  ) {
    usersCreate(
      address: $address
      companyName: $companyName
      email: $email
      name: $name
      phone: $phone
      spaceId: $spaceId
    ) {
      id
    }
  }
`;

const usersDelete = gql`
  mutation usersDelete($id: GUID!) {
    usersDelete(id: $id) {
      id
    }
  }
`;

const usersSearch = gql`
  mutation usersSearch($hitsPerPage: Int, $q: String!, $spaceId: GUID!) {
    usersSearch(hitsPerPage: $hitsPerPage, q: $q, spaceId: $spaceId) {
      hits {
        id
        name
      }
    }
  }
`;

const usersUpdate = gql`
  mutation usersUpdate(
    $id: GUID!
    $address: String
    $companyName: String
    $email: String
    $name: String!
    $phone: String
  ) {
    usersUpdate(
      id: $id
      address: $address
      companyName: $companyName
      email: $email
      name: $name
      phone: $phone
    ) {
      id
    }
  }
`;

export { usersCreate, usersDelete, usersSearch, usersUpdate };
