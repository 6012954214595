import { DataProps, useSelector } from 'dataformjs';
import objectHash from 'object-hash';
import React, { FC } from 'react';

import VehicleType from '../../../../types/Vehicle';
import VehicleItem from '../../../item/Vehicle';
import List from '../../../new/Body/List';

interface ScenarioVehiclesProps extends DataProps {
  formName: string;
}

const ScenarioVehicles: FC<ScenarioVehiclesProps> = ({ formName }) => {
  const { places, vehicles } = useSelector(
    (state: any) => state.form[formName].values,
  );

  return (
    vehicles && (
      <List>
        {vehicles.map((vehicle: VehicleType) => (
          <VehicleItem
            key={objectHash({ vehicleId: vehicle.id })}
            places={places}
            vehicle={vehicle}
          />
        ))}
      </List>
    )
  );
};

export default ScenarioVehicles;
