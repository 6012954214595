import React, { FC, ReactElement } from 'react';

import Modal from '../../base/Modal';

const SpaceModal: FC<{
  className?: string;
  contentClassName?: string;
  onClose: () => void;
  title?: string | ReactElement;
}> = ({ children, className, contentClassName, onClose, title }) => (
  <Modal
    className={className}
    contentClassName={contentClassName}
    onClose={onClose}
    title={title}
  >
    {children}
  </Modal>
);

export default SpaceModal;
