import { rem } from 'polished';
import styled from 'styled-components';

import { colors, spacing } from '../../../styles/theme';

interface TimelineStepProps {
  hasNextStep?: boolean;
}
const TimelineStep = styled.div<TimelineStepProps>`
  position: relative;
  display: flex;
  padding-bottom: ${props => (props.hasNextStep ? spacing.l : 0)};
  ${props =>
    props.hasNextStep &&
    `&::before {
    position: absolute;
    top: ${rem(29)};
    left: ${rem(10)};
    bottom: ${spacing['1']};
    display: block;
    content: '';
    width: 4px;
    border-radius: ${rem(2)};
    background: ${colors.primary.lighter};
  }`}
  @media print {
    display: block;
    border-top: 0.5mm solid ${colors.print.base};
    padding-top: 2mm;
    padding-bottom: 20mm;
    page-break-inside: avoid;
    page-break-after: auto;
    page-break-before: auto;
    &::before {
      display: none;
    }
  }
`;

export default TimelineStep;
