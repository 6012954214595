/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
module.exports = {
  family: {
    base:
      '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,' +
      '"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  weight: {
    base: 400,
    medium: 600,
    bold: 700,
  },
  size: {
    // 12px
    xs: ['0.75rem', '1rem'],
    // 20px
    xl: ['1.25rem', '1.75rem'],
    // 14px
    s: ['0.875rem', '1.25rem'],
    // 16px
    m: ['1rem', '1.5rem'],
    // 18px
    l: ['1.125rem', '1.75rem'],
    // 24px
    xxl: ['1.5rem', '2rem'],
    // 28px
    xxxl: ['1.75rem', '2.325rem'],
  },
};
