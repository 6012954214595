import { DataFieldProps } from 'dataformjs';
import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import { GroupBase, Options, OptionsOrGroups } from 'react-select';

import { placesSearch as searchMutation } from '../../../objects/places/mutations';
import PlaceType from '../../../types/Place';
import DataSelect from '../../datas/Select';

interface DataPlacesSelectProps extends DataFieldProps {
  formName: string;
  name: string;
  params: {
    spaceId: string;
  };
}

const DataPlacesSelect: FC<
  Omit<DataPlacesSelectProps, 'componentType'>
> = props => {
  const { formName, params } = props;
  const [searchMutationFunc] = useMutation(searchMutation);

  const handleGetOptionLabel = (option: any): string => option?.name ?? '';
  const handleGetOptionValue = (option: any): string => option.id;

  const handleIsOptionSelected = (
    option: any,
    optionsSelected: Options<PlaceType>,
  ): boolean => optionsSelected.some((o: PlaceType) => o.id === option.id);

  const handleLoadOptions = async (
    inputValue: string,
  ): Promise<OptionsOrGroups<PlaceType, GroupBase<PlaceType>>> => {
    let options: PlaceType[] = [];
    const result = await searchMutationFunc({
      variables: {
        hitsPerPage: 50,
        q: inputValue,
        spaceId: params.spaceId,
      },
    });

    if (
      result &&
      result.data &&
      result.data.placesSearch &&
      result.data.placesSearch.hits
    ) {
      options = result.data.placesSearch.hits;
    }

    return options;
  };

  return (
    <DataSelect
      {...props}
      formName={formName}
      getOptionLabel={handleGetOptionLabel}
      getOptionValue={handleGetOptionValue}
      isOptionSelected={handleIsOptionSelected}
      loadOptions={handleLoadOptions}
    />
  );
};

export default DataPlacesSelect;
