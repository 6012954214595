import keyBy from 'lodash/keyBy';
import objectHash from 'object-hash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { colors, font } from '../../../../../styles/theme';
import ActivityType from '../../../../../types/Activity';
import StepType from '../../../../../types/Step';
import RecapRow from '../../../../base/RecapRow';
import TimelineStep from '../../../../base/TimelineStep';
import TimelineStepBullet from '../../../../base/TimelineStep/bullet';
import TimelineStepContent from '../../../../base/TimelineStep/content';
import TimelineStepContentBody from '../../../../base/TimelineStep/content/body';
import TimelineStepContentHeader from '../../../../base/TimelineStep/content/header';
import Title from '../../../../base/Title';
import IconHourGlass from '../../../../icons/HourGlass';
import IconWatch from '../../../../icons/Watch';
import Delivery from './Delivery';

const Step: FC<{
  formName: string;
  step: StepType;
  stepIndex: number;
}> = ({ formName, step, stepIndex }) => {
  const { t } = useTranslation();
  const { stepActivities, activities, place, id, waitingDuration } = step;

  const activitiesLUT = activities ? keyBy(activities, 'id') : undefined;
  if (!activitiesLUT || 0 === Object.keys(activitiesLUT).length) {
    return <div>Aucune activité</div>;
  }

  return (
    <TimelineStep key={id}>
      <TimelineStepBullet>{stepIndex}</TimelineStepBullet>
      <TimelineStepContent className="pl-2">
        <TimelineStepContentHeader>
          <div className="md:flex md:justify-between">
            <Title color={colors.primary.base} size={font.size.m[0]}>
              {place?.name}
            </Title>
          </div>
          <ul className="p-0 mb-0 mt-2 list-none">
            <RecapRow
              borderBottom
              icon={IconWatch}
              leftChildren={t('waypoint.estimatedTimeForkLabel')}
              // rightChildren={t('waypoint.estimatedTimeFork', {
              //   from: from.format('HH:mm'),
              //   to: to.format('HH:mm'),
              // })}
            />
            <RecapRow
              borderBottom
              icon={IconHourGlass}
              leftChildren={t('waypoint.durationOnSiteLabel')}
              rightChildren={t('waypoint.durationOnSite', {
                duration: `${waitingDuration} min`,
              })}
            />
          </ul>
        </TimelineStepContentHeader>
        <TimelineStepContentBody>
          {stepActivities?.map((stepActivity: ActivityType) => (
            <Delivery
              key={objectHash({ stepActivityId: stepActivity.id })}
              place={activitiesLUT[stepActivity.id].place}
              user={activitiesLUT[stepActivity.id].user}
            />
          ))}
        </TimelineStepContentBody>
      </TimelineStepContent>
    </TimelineStep>
  );
};

export default Step;
