/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */

module.exports = {
  xs: '480px',
  smartphone: '480px',
  sm: '640px',
  tablet: '640px',
  md: '768px',
  navigation: '900px',
  lg: '1024px',
  laptop: '1024px',
  xl: '1280px',
  desktop: '1280px',
  '2xl': '1536px',
};
